.mensaje-container {
  width: 100%;
  background-color: #eceb98;
  justify-content: center;
  align-items: center;
  column-gap: 25px;
  padding: 20px 30px;
}

.mensaje {
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.mensaje span:first-child {
  color: var(--color-principal);
  text-decoration: underline;
  font-weight: bold;
}

.mensaje span:nth-child(2) {
  text-decoration: line-through;
  color: #a80000;
}

.mensaje-icon {
  color: black;
}

.mensaje-icon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .mensaje {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .mensaje {
    font-size: 1.1rem;
  }
}
