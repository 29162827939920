@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
  --color-principal: #137ca9;
  --color-secundario: #f5ba00;
  --color-terciario: #3b6075;
  --color-tipografia: #2a2a2a;

  --tipografia-titulo: "Roboto Slab", serif;
  --tipografia-contenido: "Open Sans", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "lato", serif;
}
h1,
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.4rem;
}

body {
  position: relative;
  z-index: 0;
}

.container {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

img.img-fluid {
  width: 100%;
  height: auto;
}

.contact-form {
}
.mainLoader {
  margin: 200px auto;
  width: 100px;
}
.input-group {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input-group input {
  width: 100%;
  padding: 6px 12px;
  display: block;
  height: 45px;
  line-height: 1.42857143;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

.input-group textarea {
  width: 100%;
  padding: 6px 12px;
  display: block;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
}

.btn-primary {
  background-color: var(--color-principal);
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 0.25s ease;
  text-decoration: none !important;
  padding: 18px 35px;
  font-family: "lato", sans-serif;
  font-size: 1.3rem;
  border-radius: 20px;
  min-width: 135px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.btn-primary:hover {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.img-squared {
  width: 100%;
  height: 100%;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  a.invisible-link div.tarjeta-curso {
    width: 240px;
    min-height: max-content;
  }
}
