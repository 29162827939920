/* .carousel-item {
  width: 100%;
  height: 661px;
  background-image: url(../../images/Banner-principal.jpg);
  background-size: cover;
  background-position: center;
}

.banner-principal-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-principal-container__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0 10%;
}

.banner-principal-container__texto--principal {
  color: #fff;
  font-family: var(--tipografia-titulo);
  font-size: 2.8rem;
  font-weight: bold;
  width: 30rem;
  margin-bottom: 2rem;
}

.banner-principal-container__texto--secundario {
  color: #fff;
  font-size: 1.2rem;
  width: 30rem;
}

.banner-principal-container img {
  width: 100%;
}

.carousel-tag span.futuro {
  display: block;
  color: var(--color-principal);
}

.carousel-tag span.profesionales {
  font-size: 1rem;
}

@media only screen and (max-width: 380px) {
  .banner-principal-container__texto--principal {
    font-size: 7vw;
    text-align: center;
  }
}

@media only screen and (max-width: 425px) {
  .carousel-item {
    background-image: url("../../images/360x767-Mkt-Educ-.jpg");
    background-position: center;
    height: 767px;
  }

  .banner-principal-container__texto {
    padding: 30px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.5rem;
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .banner-principal-container__texto--secundario {
    font-size: 1rem;
    width: 100%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .banner-principal-container__texto--principal,
  .banner-principal-container__texto--secundario {
    width: 100%;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.3rem;
    margin-bottom: 30px;
  }

  .banner-principal-container {
    padding: 40px 30px 0;
  }

  .banner-principal-container__texto {
    padding: 0;
    justify-content: flex-start;
  }

  .carousel-item {
    background-position: left;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .banner-principal-container__texto {
    justify-content: flex-start;
    padding: 40px 5% 0;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.3rem;
    margin-bottom: 30px;
    width: 100%;
  }

  .banner-principal-container__texto--secundario {
    width: 100%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 1024px) {
  .carousel-item {
    background-image: url("../../images/670x1024-Mkt-Educ-H-sintext.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 670px;
  }
}
 */

.carousel-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.carousel-tag {
  width: 30%;
  height: max-content;
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  text-align: left;
  color: #fff;
}

.carousel-tag.tag-2 {
  width: 35%;
  font-size: 3.2rem;
  font-weight: bold;
  margin-left: 100px;
}

.carousel-tag.tag-1 {
  margin-left: 100px;
}

.letra-fina {
  font-size: 2.5rem;
  font-weight: 400;
}

.carusel-amarillo {
  font-size: 3.5rem;
  font-weight: 900;
  color: var(--color-secundario);
}

.carousel-tag p {
  color: #fff;
  margin-top: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.carousel-item:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.tag-1 img,
.tag-2 img {
  width: 100%;
}

.dnone {
	display: none !important;
}

.slider.animated li:nth-child(2) {
  display: none;
}

@media only screen and (max-width: 425px) {
  .carousel-tag.tag-1 {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    align-items: center;
  }

  .carousel-tag.tag-1 p {
    font-size: 1rem;
    width: 100%;
  }

  .carousel-tag {
    padding: 20px;
  }

  .carousel-tag.tag-2 {
    font-size: 1.3rem;
    margin-left: 10px;
  }

  .carusel-amarillo {
    font-size: 1.8rem;
  }

  .letra-fina {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .carousel-tag.tag-1 {
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
  }

  .carousel-tag.tag-1 p {
    width: 100%;
    font-size: 1rem;
  }

  .carousel-tag.tag-2 {
    margin-left: 20px;
  }

  .carousel-tag {
    padding: 20px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 460px) {
  .carousel-tag.tag-2 {
    font-size: 1rem;
  }

  .carusel-amarillo {
    font-size: 1.5rem;
  }

  .letra-fina {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 460px) and (max-width: 650px) {
  .carousel-tag.tag-2 {
    font-size: 1.3rem;
  }

  .carusel-amarillo {
    font-size: 1.8rem;
  }

  .letra-fina {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
  .carousel-tag.tag-2 {
    margin-left: 50px;
    font-size: 1.6rem;
  }

  .carusel-amarillo {
    font-size: 2rem;
  }

  .letra-fina {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-tag.tag-1 {
    font-size: 1.5rem;
  }

  .carousel-tag.tag-1 p {
    font-size: 1rem;
  }

  .carousel-tag.tag-2 {
    font-size: 1.5rem;
    margin-left: 60px;
  }

  .carusel-amarillo {
    font-size: 2rem;
  }

  .letra-fina {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1190px) {
  .carousel-tag.tag-1 {
    font-size: 1.8rem;
  }

  .carousel-tag.tag-1 p {
    font-size: 1.2rem;
  }

  .carousel-tag.tag-2 {
    font-size: 2rem;
    margin-left: 80px;
  }

  .carusel-amarillo {
    font-size: 2.5rem;
  }

  .letra-fina {
    font-size: 2.5rem;
  }
}
