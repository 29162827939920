.label-inscripcion {
  margin-bottom: 5px;
  color: #aaa;
}

.label-inscripcion::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.datos-personales h1 {
  margin-bottom: 20px;
}

.pago-title,
.pedido-title {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: black;
}

table.shop_table.woocommerce-checkout-review-order-table {
  margin-bottom: 25px;
}

td,
th {
  color: rgb(168 167 168);
}

span.pago-option {
  display: inline-block;
  margin-bottom: 5px;
}

.card {
  width: 100%;
  height: max-content;
  padding: 20px;
  background-color: #f0f0ed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-pago__fecha,
.input-pago {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

#ccNo,
#cvv {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  background-color: #fafafa;
  border: none;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

div.input-pago__fecha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}

.input-pago__fecha label {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.input-pago__fecha input {
  width: 50%;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  margin-top: 5px;
}

span.pago-option {
  font-size: 1rem;
  font-family: "lato";
  font-weight: 500;
}

select {
  margin-top: 5px;
  width: 30%;
  height: 40px;
}

select option {
  font-family: "Open Sans", sans-serif;
}

.mensaje-cvs {
  margin-top: 5px;
  color: #aaa;
}

.mensaje-cvs::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.submit-section {
  display: flex;
  flex-direction: column;
}

.submit-section img {
  width: 75%;
  margin: 0 auto 15px auto;
}

.submit-section input {
  margin: 0 auto;
}

.product-name,
.product-total,
.cart-subtotal th,
.order-total th,
span.woocommerce-Price-currencySymbol,
bdi {
  color: black;
}

.test {
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.error {
  background-color: red;
}

.success {
  background-color: rgb(7, 160, 7);
}

.pending {
  background-color: yellow;
  color: black !important;
}

.acceder-holder {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 45px;
}

@media only screen and (max-width: 1024px) {
  .acceder-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px 0;
  }

  #tcoCCForm {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .datos-personales {
    grid-row-start: 1;
    grid-row-end: 2;
    height: max-content;
  }

  .pedido {
    grid-row-start: 2;
    grid-row-end: 3;
    height: auto;
  }

  h3.pago-title,
  h3.pedido-title {
    font-size: 1.5rem;
  }

  h3.pedido-title {
    margin-bottom: 20px;
  }

  h3.pago-title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  select {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .datos-personales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-pago__fecha,
  .input-pago {
    margin: 0 auto 10px auto;
  }

  #ccNo,
  #cvv {
    height: 30px;
  }

  .mensaje-cvs {
    font-size: 4vw;
  }

  div.input-pago__fecha {
    flex-direction: column;
    align-items: center;
  }

  span.pago-option {
    font-size: 0.8rem;
  }

  .input-pago__fecha label {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-pago__fecha label br {
    display: none;
  }

  select {
    width: 45%;
  }

  .input-pago__fecha label div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submit-section {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .submit-section img {
    width: 45%;
    margin: 0;
  }

  .submit-section input {
    width: 45%;
    margin: 0;
  }
}

#iframe-container-bancard {
  height: auto;
  width: 100%;
  margin: auto;
}

iframe[src~="infonet"], 
iframe[src^="https://wpos.infonet"],
#iframe-container-bancard iframe {
  display: block !important;
}
