.section-title {
  height: 168px;
  background-size: cover;
  overflow: hidden;
  display: flex;
}

.section-title h1 {
  color: white;
  font-family: "lato", sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.section-title h2 {
  color: white;
  font-family: "lato", sans-serif;
  font-size: 1.6rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 425px) {
  .section-title h1 {
    font-size: 1rem;
  }

  .section-title h2 {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .section-title h1 {
    font-size: 1.4rem;
  }
}
