.botonDeSubir {
  width: 60px;
  height: 60px;
  background-color: var(--color-principal);
  border: 3px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  bottom: 30px;
  right: 30px;
}

.botonDeSubir svg {
  color: #fff;
}

.botonDeSubir:hover,
.botonDeSubir svg:hover {
  cursor: pointer;
}
