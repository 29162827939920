li.react-multi-carousel-item.react-multi-carousel-item--active {
  /* margin: 0 10px; */
  width: max-content;
}

.tarjeta-curso-info {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tarjeta-curso {
  width: 350px;
  min-height: 300px;
  background: white;
  margin: auto;
  border: 1px solid #eaeaea;
  transition: ease all 0.3s;
}

.invisible-link {
}

.tarjeta-curso:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso span {
  color: #999;
  text-align: center;
}

.tarjeta-curso img {
  width: 100%;
  object-fit: cover;
}

.tarjeta-curso h2.title {
  color: black;
  font-size: 1.3rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .tarjeta-curso img {
    height: 120px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1110px) {
  .tarjeta-curso {
    width: auto;
  }
}
