.background-cookie {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(10px);
  display: flex;
}

.modal-cookie {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  background-color: #edeff5;
  padding: 2%;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-cookie__content p {
  color: black;
  font-size: 1rem;
  margin: 0 0 10px 0;
  color: #838391;
}

.modal-cookie__content p a {
  color: #afb0ba;
}

.modal-cookie__content p a:hover {
  color: #838391;
}

div.modal-cookie__buttons {
  width: 20%;
  display: flex;
  font-size: 1rem;
  justify-content: center;
}

.modal-cookie button {
  height: 30px;
  width: 50%;
  margin: 0 10px;
  background-color: #0099d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
}

button.modal-cookie__buttons--reject {
  color: #0099d5;
  background-color: #edeff5;
  border: 1px solid #0099d5;
  font-weight: 500;
}

.modal-cookie button:hover {
  cursor: pointer;
}

.modal-cookie.true {
  display: none;
}

.modal-cookie.false {
  display: flex;
}

.ocultar-true {
  display: none !important;
}

.ocultar-fondo-true {
  display: none;
}

/*
.ocultar-false{
  display: flex;
}*/

@media only screen and (max-width: 426px) {
  .modal-cookie__content p {
    text-align: center;
  }

  div.modal-cookie__buttons {
    width: 80%;
  }

  .modal-cookie__content p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  div.modal-cookie__buttons {
    width: 50%;
  }

  .modal-cookie__content p {
    font-size: 0.9rem;
  }
}
