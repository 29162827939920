header {
  width: 100%;
  padding: 0.8rem 0;
}

.mobile-menu {
  display: none;
}

div#root div.container-header {
  padding: 0 180px;
}

nav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  background: white;
  column-gap: 20px;
}

nav span {
  display: none;
}

nav a img {
  max-height: 55px;
}

nav span:hover {
  color: var(--color-principal);
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul a {
  width: max-content;
}

nav ul a li {
  list-style: none;
  margin: 0;
  font-family: "lato", serif;
  font-weight: 700;
  line-height: 40px;
  padding: 0 20px;
}

nav ul a li {
  color: black;
  border-bottom: 3px solid transparent;
  transition: ease all 0.3s;
}

nav ul a li.active:after {
  content: " ";
  display: block;
  background: var(--color-principal);
  width: 100%;
  height: 3px;
  padding: 0;
}

nav ul a:hover li {
  color: var(--color-principal);
}

nav ul a li.nav-btn {
  background: var(--color-principal);
  height: 40px;
  border-radius: 12px;
}

nav ul a li.nav-btn {
  color: white;
}

@media only screen and (max-width: 425px) {
  div#root div.container-header {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1380px) {
  div#root div.container-header {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 1024px) {
  div#root div.container-header {
    padding: 0 50px;
  }

  nav a img {
    max-height: 40px;
  }
}

@media only screen and (max-width: 950px) {
  nav ul {
    display: none;
  }

  nav span {
    display: block;
  }

  .mobile-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    width: 100%;
    right: 0;
  }

  .mobile-menu ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 4px solid #eaeaea;
  }

  .mobile-menu ul a li {
    width: 100%;
    list-style: none;
    padding: 0 20px;
    background: #fafafa;
    font-family: "lato", serif;
    font-weight: 700;
    line-height: 50px;
    border-bottom: 1px solid #eaeaea;
  }

  .mobile-menu ul a li {
    color: #666;
    transition: ease all 0.3s;
  }

  .mobile-menu ul a:hover {
    color: var(--color-principal);
  }

  .mobile-menu ul a:hover li {
    color: var(--color-principal);
  }
}

section.section-title {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 425px) {
  nav a img {
    max-height: 30px;
  }

  section.section-title {
    height: 90px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1360px) {
  nav ul a li {
    padding: 0 10px;
    font-size: 1rem;
  }
}
