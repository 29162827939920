.input-group .nombre,
.input-group .email {
  width: 47%;
}

.svg.svg-inline--fa.fa-caret-left.fa-w-6 path {
  color: white;
}

button.btn-primary.button-nosotros-form {
  background-color: var(--color-principal);
}

.seccion-enviar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-nosotros-form {
  height: max-content;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .input-group .nombre,
  .input-group .email {
    width: 100%;
    margin-bottom: 10px;
  }

  .seccion-enviar {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
  }

  .recaptcha {
    grid-row: 1 / 2;
  }

  .seccion-enviar button {
    grid-row: 2 / 3;
  }
}
/*
 Fix to iframe display none on Cursos.css
*/
.recaptcha iframe {
  display: block !important;
}

iframe[style="display: none;"] {
  display: none;
  background: red;
  display: none !important;
}

select[name="pais"] {
	width: 100%;
	border: white;
	color: #9b9c9e;
	background-color: #f0f2f5;
	padding-left: 10px;
}