.pay-card-body input:hover,
.pay-card-body button:hover,
.pay-card-body select:hover {
  cursor: not-allowed;
}

.pay-card-container {
  width: 100%;
}

.display-none {
  display: none;
}

.pay-card-header {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}

.pay-card-header img {
  width: 50%;
}

.pay-card-body {
  border: 1px solid #dddddd;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 15px;
}

.pay-card-number {
  display: flex;
  margin-bottom: 10px;
}

.card-img-container {
  border: 1px solid #dddddd;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  padding: 5px 10px;
}

.card-img-container img {
  width: 20px;
  height: 20px;
}

.pay-card-number input {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
  color: #555555;
  font-size: 14px;
}

.pay-card-data {
  width: 100%;
}

.pay-card-data__date,
.pay-card-data__cvc,
.numer-payments {
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0 10px;
  color: #555555;
  height: 34px;
}

.pay-card-data__date {
  width: 60%;
  margin-right: 5px;
}

.pay-card-data__cvc {
  width: calc(40% - 5px);
}

hr {
  border: 1px solid #eeeeee;
  width: 100%;
  margin: 10px 0;
}

.numer-payments {
  width: 60%;
  height: 34px;
}

.card-button-pay {
  width: 100%;
  padding: 10px 16px;
  color: #fff;
  background-color: #1783b8;
  border: 1px solid #1783b8;
  border-radius: 5px;
  font-size: 18px;
  margin-bottom: 10px;
}

div.logos {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pago-seguro {
  width: 150px;
  height: 48px;
  border-radius: 5px;
  margin-right: 10px;
}

.pci {
  width: 125px;
  height: 38px;
}

@media screen and (max-width: 1214px) {
  div.logos {
    justify-content: center;
  }
}
