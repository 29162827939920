.tarjeta-profe h3 {
  color: black;
  font-size: 1.3rem;
  text-align: center;
}

.tarjeta-profe .tarjeta-curso-info {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tarjeta-profe .tarjeta-curso-info h2 {
  margin: 20px 0;
}

@media only screen and (max-width: 425px) {
  div.tarjeta-curso img {
    height: auto;
    background-position: center;
  }
}
