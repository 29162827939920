.custom-button-group {
  width: 100px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0 auto;
}

.custom-button-group button {
  cursor: pointer;
  width: 46px;
  height: 46px;
  line-height: 46px;
  color: white;
  background: var(--color-principal);
  border: none;
}

.carousel-parent {
  min-height: 350px;
  margin-top: 30px;
}

.carousel-parent h2.tipo-de-cursos {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 2rem;
}

/* ul.react-multi-carousel-track {
  height: 370px;
} */

.carousel-description {
  margin-bottom: 50px;
  font-weight: 500;
  padding: 0 100px;
  font-size: 1.1rem;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 425px) {
  ul.react-multi-carousel-track {
    height: 320px;
  }

  .container.carousel-home {
    margin: 40px 0 0;
    padding: 0;
    max-width: none;
    width: 100%;
  }

  .container.carousel-home.segundo-carusel {
    margin: 0;
  }

  .carousel-parent,
  .carousel-parent h2.tipo-de-cursos {
    margin-top: 0;
  }

  .carousel-parent {
    padding: 0 15px;
  }

  .carousel-parent h2.tipo-de-cursos {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .carousel-description {
    padding: 0;
    font-size: 1rem;
  }

  /*   .carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
}

@media only screen and (max-width: 768px) {
  .carousel-parent {
    margin: 0;
  }

  .carousel-description {
    padding: 0;
  }

  .carousel-parent h2.tipo-de-cursos {
    margin-top: 0;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1100px) {
  .react-multi-carousel-track {
    column-gap: 20px;
  }

  .react-multi-carousel-track li {
    width: auto;
  }
}

.carusel-profes h2 {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 2rem;
}
