@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
:root {
  --color-principal: #137ca9;
  --color-secundario: #f5ba00;
  --color-terciario: #3b6075;
  --color-tipografia: #2a2a2a;

  --tipografia-titulo: "Roboto Slab", serif;
  --tipografia-contenido: "Open Sans", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "lato", serif;
}
h1,
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.4rem;
}

body {
  position: relative;
  z-index: 0;
}

.container {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

img.img-fluid {
  width: 100%;
  height: auto;
}

.contact-form {
}
.mainLoader {
  margin: 200px auto;
  width: 100px;
}
.input-group {
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input-group input {
  width: 100%;
  padding: 6px 12px;
  display: block;
  height: 45px;
  line-height: 1.42857143;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

.input-group textarea {
  width: 100%;
  padding: 6px 12px;
  display: block;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
}

.btn-primary {
  background-color: #137ca9;
  background-color: var(--color-principal);
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 0.25s ease;
  text-decoration: none !important;
  padding: 18px 35px;
  font-family: "lato", sans-serif;
  font-size: 1.3rem;
  border-radius: 20px;
  min-width: 135px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.btn-primary:hover {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.img-squared {
  width: 100%;
  height: 100%;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  a.invisible-link div.tarjeta-curso {
    width: 240px;
    min-height: -webkit-max-content;
    min-height: max-content;
  }
}

.botonDeSubir {
  width: 60px;
  height: 60px;
  background-color: var(--color-principal);
  border: 3px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  bottom: 30px;
  right: 30px;
}

.botonDeSubir svg {
  color: #fff;
}

.botonDeSubir:hover,
.botonDeSubir svg:hover {
  cursor: pointer;
}

footer {
  width: 100%;
  background-color: #195f78;
  color: white;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

footer h4 {
  font-size: 1rem;
  font-weight: 200;
}

footer .container {
  margin: 0;
  width: 100%;
  max-width: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0;
}

footer .container .acerca,
footer .container .contacto,
footer .container .paginas,
footer .container .blog-footer {
  margin: 0 45px;
}

footer h3 {
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 40px;
}

footer .paginas ul {
  padding-left: 20px;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}

footer .paginas ul li {
  width: -webkit-max-content;
  width: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 5px;
}

footer .paginas ul li a {
  color: white;
  transition: ease all 0.3s;
  font-size: 1rem;
  font-weight: 200;
}

footer .paginas ul li a:hover {
  color: goldenrod;
}

footer .paginas,
footer .blog-footer {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer .blog-footer .entries {
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
}

footer .blog-footer .entry {
  display: flex;
  width: 100%;
}

footer .blog-footer .entry .img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

footer .blog-footer .entry .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

footer .blog-footer .entry .title {
  width: 200px;
  display: flex;
  align-items: flex-start;
}

/*
Para las direcciones
*/

.ul_dir {
  list-style: none;
  text-align: center;
}

footer .container h3 {
  font-size: 1.3rem;
  font-family: "lato", serif;
}

.contacto a {
  color: white;
}

.numeros {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

footer a,
footer p,
footer .paginas ul li {
  font-size: 0.9rem;
}

.derechos-reservados {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.derechos-reservados__texto {
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.seccion-links {
  display: flex;
  grid-column-gap: 30px;
  column-gap: 30px;
}

.footer_titulo--mayuscula::first-letter{
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  footer {
    height: -webkit-max-content;
    height: max-content;
    padding: 30px 40px;
  }

  footer .blog-footer .entries {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  footer .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    align-items: center;
  }

  footer .container h3 {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto 20px;
  }

  footer h4 {
    text-align: left;
  }

  footer .blog-footer,
  footer .paginas {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .derechos-reservados__texto {
    text-align: center;
  }

  footer .blog-footer .entry .img {
    flex: none;
  }

  footer .paginas ul li a {
    font-size: 1rem;
  }

  footer .container div {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 0;
  }

  footer .container div.paginas {
    text-align: left;
  }
}

header {
  width: 100%;
  padding: 0.8rem 0;
}

.mobile-menu {
  display: none;
}

div#root div.container-header {
  padding: 0 180px;
}

nav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  background: white;
  grid-column-gap: 20px;
  column-gap: 20px;
}

nav span {
  display: none;
}

nav a img {
  max-height: 55px;
}

nav span:hover {
  color: var(--color-principal);
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul a {
  width: -webkit-max-content;
  width: max-content;
}

nav ul a li {
  list-style: none;
  margin: 0;
  font-family: "lato", serif;
  font-weight: 700;
  line-height: 40px;
  padding: 0 20px;
}

nav ul a li {
  color: black;
  border-bottom: 3px solid transparent;
  transition: ease all 0.3s;
}

nav ul a li.active:after {
  content: " ";
  display: block;
  background: var(--color-principal);
  width: 100%;
  height: 3px;
  padding: 0;
}

nav ul a:hover li {
  color: var(--color-principal);
}

nav ul a li.nav-btn {
  background: var(--color-principal);
  height: 40px;
  border-radius: 12px;
}

nav ul a li.nav-btn {
  color: white;
}

@media only screen and (max-width: 425px) {
  div#root div.container-header {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1380px) {
  div#root div.container-header {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 1024px) {
  div#root div.container-header {
    padding: 0 50px;
  }

  nav a img {
    max-height: 40px;
  }
}

@media only screen and (max-width: 950px) {
  nav ul {
    display: none;
  }

  nav span {
    display: block;
  }

  .mobile-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    width: 100%;
    right: 0;
  }

  .mobile-menu ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 4px solid #eaeaea;
  }

  .mobile-menu ul a li {
    width: 100%;
    list-style: none;
    padding: 0 20px;
    background: #fafafa;
    font-family: "lato", serif;
    font-weight: 700;
    line-height: 50px;
    border-bottom: 1px solid #eaeaea;
  }

  .mobile-menu ul a li {
    color: #666;
    transition: ease all 0.3s;
  }

  .mobile-menu ul a:hover {
    color: var(--color-principal);
  }

  .mobile-menu ul a:hover li {
    color: var(--color-principal);
  }
}

section.section-title {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 425px) {
  nav a img {
    max-height: 30px;
  }

  section.section-title {
    height: 90px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1360px) {
  nav ul a li {
    padding: 0 10px;
    font-size: 1rem;
  }
}

.mensaje-container {
  width: 100%;
  background-color: #eceb98;
  justify-content: center;
  align-items: center;
  grid-column-gap: 25px;
  column-gap: 25px;
  padding: 20px 30px;
}

.mensaje {
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.mensaje span:first-child {
  color: var(--color-principal);
  text-decoration: underline;
  font-weight: bold;
}

.mensaje span:nth-child(2) {
  text-decoration: line-through;
  color: #a80000;
}

.mensaje-icon {
  color: black;
}

.mensaje-icon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .mensaje {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .mensaje {
    font-size: 1.1rem;
  }
}

.background-cookie {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
          backdrop-filter: saturate(180%) blur(10px);
  display: flex;
}

.modal-cookie {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  background-color: #edeff5;
  padding: 2%;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-cookie__content p {
  color: black;
  font-size: 1rem;
  margin: 0 0 10px 0;
  color: #838391;
}

.modal-cookie__content p a {
  color: #afb0ba;
}

.modal-cookie__content p a:hover {
  color: #838391;
}

div.modal-cookie__buttons {
  width: 20%;
  display: flex;
  font-size: 1rem;
  justify-content: center;
}

.modal-cookie button {
  height: 30px;
  width: 50%;
  margin: 0 10px;
  background-color: #0099d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
}

button.modal-cookie__buttons--reject {
  color: #0099d5;
  background-color: #edeff5;
  border: 1px solid #0099d5;
  font-weight: 500;
}

.modal-cookie button:hover {
  cursor: pointer;
}

.modal-cookie.true {
  display: none;
}

.modal-cookie.false {
  display: flex;
}

.ocultar-true {
  display: none !important;
}

.ocultar-fondo-true {
  display: none;
}

/*
.ocultar-false{
  display: flex;
}*/

@media only screen and (max-width: 426px) {
  .modal-cookie__content p {
    text-align: center;
  }

  div.modal-cookie__buttons {
    width: 80%;
  }

  .modal-cookie__content p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  div.modal-cookie__buttons {
    width: 50%;
  }

  .modal-cookie__content p {
    font-size: 0.9rem;
  }
}

.section-title {
  height: 168px;
  background-size: cover;
  overflow: hidden;
  display: flex;
}

.section-title h1 {
  color: white;
  font-family: "lato", sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.section-title h2 {
  color: white;
  font-family: "lato", sans-serif;
  font-size: 1.6rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 425px) {
  .section-title h1 {
    font-size: 1rem;
  }

  .section-title h2 {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .section-title h1 {
    font-size: 1.4rem;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tsection-title-tipota-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "lato";
  color: #222222;
}

.section-title-tipo {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  padding: 20px 20px;
}

.info-suscripcion {
  width: 100%;
}

.tipo {
  position: relative;
  top: 14px;
}

.input-group {
  /* position: relative; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  /* margin-bottom: 5px; */
}

.woocommerce-checkout-review-order-table {
  width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.shop_table tr {
  border: 0;
}

.cursos-online {
  color: var(--color-secundario);
  font-size: 1.2rem;
  font-weight: 700;
}

.shop_table > tbody > tr td {
  padding: 30px 8px;
  vertical-align: middle;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce-checkout-review-order-table td.product-name,
.woocommerce-checkout-review-order-table th {
  text-align: left;
}

.woocommerce .shop_table.order_details .product-total,
.woocommerce-checkout-review-order-table td,
.woocommerce-checkout-review-order-table th.product-total {
  text-align: right;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce .shop_table.order_details > tbody > tr:nth-of-type(2n + 1),
.woocommerce-checkout-review-order-table > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}

.shop_table > tbody > tr {
  border: 0;
}

.info-container {
  height: 100%;
  width: 50%;
  display: flex;
}

.info-suscripcion ul {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.info-suscripcion ul div {
  display: flex;
  align-items: baseline;
}

.info-suscripcion ul div li {
  list-style: none;
}

.info-container img {
  width: 70%;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  align-self: end;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text .pago-anual,
.text .precio {
  text-align: center;
}

.text p,
.text span {
  margin: 10px 0;
}

.info-container .text .pago-anual {
  margin-bottom: 0;
  font-size: 1.7rem;
  font-weight: 700;
}

.info-container .text .precio {
  margin-top: 0;
  color: var(--color-principal);
  font-size: 1.2rem;
  font-weight: 500;
}

.precio .usd {
  font-size: 1.5rem;
  font-weight: bolder;
}

.precio .precio-original {
  color: red;
  font-weight: 700;
  text-decoration: line-through;
}

.text p {
  font-size: 1rem;
}

.text p a {
  color: black;
  transition: 0.2s;
}

.text p a:hover {
  transition: 0.3s;
  color: var(--color-principal);
}

.man-info {
  width: 100%;
  height: 368px;
  background-image: url(/static/media/Banner-inscripcion-2.90a57464.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  overflow: hidden;
}

.man-info__text {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-content: center;
}

.man-info__text--title {
  font-family: Roboto;
  text-transform: uppercase;
  color: #fff;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 37px;
  margin-bottom: 10px;
}

.man-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.man-image {
  width: 70%;
  grid-column-start: 3;
  grid-column-end: 4;
}

.man-info__text,
.man-section {
  height: 100%;
}

.kids-courses {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.cercle-blue {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}

.cercle-blue {
  background-color: rgb(0, 152, 215);
}

div.courses-logo img {
  width: 15%;
  margin-right: 10px;
}

.info-pay-section {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  grid-gap: 0 30px;
  margin: 50px 0;
}

.pay-includes {
  grid-column-start: 2;
  grid-column-end: 3;
}

.pay-actualizations {
  grid-column-start: 3;
  grid-column-end: 4;
}

.pay-includes,
.pay-actualizations {
  display: flex;
  flex-direction: column;
}

.pay-includes h3,
.pay-actualizations h3 {
  margin-bottom: 10px;
  color: black;
}

svg.svg-inline--fa.fa-check.fa-w-16 path,
svg.svg-inline--fa.fa-circle.fa-w-16 path {
  color: var(--color-principal);
}

.check-section {
  display: grid;
}

.check-text {
  display: flex;
  align-items: baseline;
}

div.chech-text svg.svg-inline--fa.fa-w-16 {
  width: 10px;
}

.check-text p {
  margin-bottom: 4px;
  margin-left: 5px;
  font-size: 1rem;
}

h3 {
  margin-bottom: 30px;
}

.guarantee-section {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 20px 0;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  background-image: url(/static/media/Banner-inscripcion-3.b2bcb2ab.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.conditions,
.guarantee {
  width: 85%;
}

.conditions {
  grid-column-start: 2;
  grid-column-end: 3;
  height: -webkit-max-content;
  height: max-content;
}

.guarantee {
  grid-column-start: 3;
  grid-column-end: 4;
  background-color: white;
  border: 3px solid #a2a2a2;
  border-radius: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  height: -webkit-max-content;
  height: max-content;
  font-family: Roboto;
  font-size: 1vw;
  margin: 0 auto;
}

.guarantee p {
  text-align: center;
  font-family: Roboto;
  line-height: 25px;
  font-size: 1rem;
}

.guarantee img {
  width: 30%;
  margin-top: 20px;
}

svg.fa-circle.circulo-acceder {
  background-color: var(--color-principal);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.conditions h2,
.guarantee h2 {
  margin-bottom: 20px;
  font-family: Roboto;
}

.conditions h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 900;
}

.guarantee h2 {
  font-size: 1.5rem;
  display: inline-block;
  text-align: center;
}

.container-form {
  padding: 20px 130px;
  width: 100%;
}

.info-suscripcion p.aceptar-terminos-privacidad {
  width: 100%;
  font-size: 1rem;
  text-align: center;
}

p.aceptar-terminos-privacidad a {
  color: var(--color-principal);
  font-weight: 600;
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .info-container {
    width: 100%;
  }

  .info-container img {
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 0;
    width: 100%;
  }

  .info-container .text {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .section-title-tipo {
    height: 100%;
    width: 100%;
    padding: 0;
  }

  .text p {
    margin: 5px 0;
    font-size: 1.8vw;
    font-weight: 400;
  }

  .info-container .text .pago-anual {
    font-size: 2.3vw;
  }

  .precio .usd {
    font-size: 3vw;
  }

  .info-container .text .precio {
    font-size: 2vw;
  }

  .text p,
  .text span {
    margin-bottom: 0;
  }

  .man-info {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px 0 20px;
  }

  .man-info__text {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .man-section {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .man-info__text--title {
    font-size: 2.5vw;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .info-pay-section {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 2.3vw;
    margin-bottom: 30px;
  }

  .check-text p {
    font-size: 1.6vw;
  }

  .guarantee-section {
    grid-template-rows: 0.5fr 0.5fr;
    grid-template-columns: 1fr;
    padding: 20px 60px;
    grid-gap: 20px 0;
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 50vw;
    height: 50vh;
  }

  .conditions h2 {
    font-size: 2.5vw;
  }

  svg.svg-inline--fa.fa-circle.fa-w-16 {
    width: 10px;
    height: 10px;
  }

  div.conditions div.check-text p {
    font-size: 1.8vw;
    font-weight: 400;
  }

  .guarantee {
    height: -webkit-max-content;
    height: max-content;
    margin: auto auto;
    width: 70%;
    height: max-content;
    padding: 20px;
  }

  .guarantee p {
    font-size: 2vw;
    line-height: 20px;
  }

  .guarantee img {
    width: 25%;
  }
}

.boton-seccion-inscribirse {
  width: 50%;
  background-color: var(--color-principal);
  color: #fff;
  margin-top: 10px;
  border: none;
  border-radius: 20px;
  padding: 10px 0;
  font-size: 1.3rem;
}

.boton-seccion-inscribirse a:visited {
  color: #fff;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .container-form {
    width: 100%;
    padding: 10px 30px;
    height: auto;
  }
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  .section-title-tipo {
    background-position: right;
    padding: 15px 10px;
    height: auto;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .info-container img {
    display: none;
  }

  .man-info {
    grid-template-columns: 1fr;
    padding: 10px 30px;
  }

  .man-image {
    display: none;
  }

  .man-info__text--title {
    font-size: 5vw;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .kids-courses {
    margin-bottom: 10px;
  }

  .cercle-blue {
    width: 10px;
    height: 10px;
  }

  .courses-logo {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .info-pay-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    grid-gap: 20px 0;
    margin: 30px 0;
    padding: 0 30px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 5vw;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .check-text p {
    font-size: 3.5vw;
  }

  .check-text {
    align-items: center;
  }

  .text p,
  .text span {
    font-size: 3.5vw;
  }

  .guarantee-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    padding: 30px 30px;
  }

  .conditions h2 {
    font-size: 4vw;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 20px 0;
    width: 100%;
    padding: 15px 10px;
    height: -webkit-max-content;
    height: max-content;
  }

  div.check-text svg:not(:root).svg-inline--fa {
    margin: 0;
    margin-bottom: auto;
  }

  div.guarantee h2 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .guarantee p {
    font-size: 3vw;
    font-weight: 600;
    /* text-align: left; */
    line-height: 20px;
  }

  .guarantee p br {
    display: none;
  }

  .guarantee img {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
  .info-container .text .pago-anual {
    font-size: 1.4rem;
  }

  .precio .usd {
    font-size: 2rem;
  }

  .text p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 603px) {
  .info-container .text .pago-anual {
    line-height: 40px;
  }
}

@media only screen and (min-width: 603px) and (max-width: 800px) {
  .info-container .text .pago-anual {
    line-height: 25px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .info-container .text .pago-anual {
    line-height: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .info-suscripcion {
    width: 100%;
  }

  .text {
    width: 100%;
  }

  .info-container .text .pago-anual {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }

  .info-container .text .precio {
    font-size: 5vw;
  }

  .precio .usd {
    font-size: 1.5rem;
  }

  .precio .precio-original {
    font-size: 1.5rem;
  }

  .text p,
  .text span {
    font-size: 1rem;
  }

  .section-title-tipo {
    background-position: right;
    padding: 15px;
    height: auto;
  }

  .info-container {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .section-title-tipo {
    background-position-x: -350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1330px) {
  .section-title-tipo {
    background-position-x: -270px;
  }
}

.nosotros-holder {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
  padding: 0 10%;
}

.nosotros-holder h2 {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}

.nosotros-holder p {
  margin-bottom: 1.2rem;
}

.form-nosotros ul {
  display: flex;
  background: var(--color-principal);
  color: white;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  padding: 1% 1% 0;
  font-weight: 700;
  border: none;
}

.form-nosotros ul li {
  margin-right: 30px;
}
.form-nosotros .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
}

.react-tabs__tab-panel {
  margin-top: 30px;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .nosotros-holder h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .nosotros-holder p {
    font-size: 0.9rem;
  }

  .cuarta-seccion-nosotros #react-tabs-2 {
    margin-right: 0;
  }

  .cuarta-seccion-nosotros li#react-tabs-2,
  li#react-tabs-0 {
    text-align: center;
  }

  .form-nosotros .contact-form,
  .cuarta-seccion-nosotros p,
  .primera-seccion-nosotros {
    padding: 0 10%;
  }

  form.contact-form button.button-nosotros-form {
    min-width: 100px;
    padding: 8px 25px;
  }

  img.img-fluid {
    margin: 20px 0;
  }

  ul.react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
  }

  li#react-tabs-2,
  li#react-tabs-0 {
    padding: 20px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 426px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    align-items: center;
  }

  .nosotros-holder p {
    font-size: 0.8rem;
  }

  .form-nosotros ul {
    display: flex;
  }

  .nosotros-holder div {
    width: 100%;
  }
}

.pay-card-body input:hover,
.pay-card-body button:hover,
.pay-card-body select:hover {
  cursor: not-allowed;
}

.pay-card-container {
  width: 100%;
}

.display-none {
  display: none;
}

.pay-card-header {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}

.pay-card-header img {
  width: 50%;
}

.pay-card-body {
  border: 1px solid #dddddd;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 15px;
}

.pay-card-number {
  display: flex;
  margin-bottom: 10px;
}

.card-img-container {
  border: 1px solid #dddddd;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  padding: 5px 10px;
}

.card-img-container img {
  width: 20px;
  height: 20px;
}

.pay-card-number input {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
  color: #555555;
  font-size: 14px;
}

.pay-card-data {
  width: 100%;
}

.pay-card-data__date,
.pay-card-data__cvc,
.numer-payments {
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0 10px;
  color: #555555;
  height: 34px;
}

.pay-card-data__date {
  width: 60%;
  margin-right: 5px;
}

.pay-card-data__cvc {
  width: calc(40% - 5px);
}

hr {
  border: 1px solid #eeeeee;
  width: 100%;
  margin: 10px 0;
}

.numer-payments {
  width: 60%;
  height: 34px;
}

.card-button-pay {
  width: 100%;
  padding: 10px 16px;
  color: #fff;
  background-color: #1783b8;
  border: 1px solid #1783b8;
  border-radius: 5px;
  font-size: 18px;
  margin-bottom: 10px;
}

div.logos {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pago-seguro {
  width: 150px;
  height: 48px;
  border-radius: 5px;
  margin-right: 10px;
}

.pci {
  width: 125px;
  height: 38px;
}

@media screen and (max-width: 1214px) {
  div.logos {
    justify-content: center;
  }
}

.label-inscripcion {
  margin-bottom: 5px;
  color: #aaa;
}

.label-inscripcion::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.datos-personales h1 {
  margin-bottom: 20px;
}

.pago-title,
.pedido-title {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: black;
}

table.shop_table.woocommerce-checkout-review-order-table {
  margin-bottom: 25px;
}

td,
th {
  color: rgb(168 167 168);
}

span.pago-option {
  display: inline-block;
  margin-bottom: 5px;
}

.card {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 20px;
  background-color: #f0f0ed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-pago__fecha,
.input-pago {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

#ccNo,
#cvv {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  background-color: #fafafa;
  border: none;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

div.input-pago__fecha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}

.input-pago__fecha label {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.input-pago__fecha input {
  width: 50%;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  margin-top: 5px;
}

span.pago-option {
  font-size: 1rem;
  font-family: "lato";
  font-weight: 500;
}

select {
  margin-top: 5px;
  width: 30%;
  height: 40px;
}

select option {
  font-family: "Open Sans", sans-serif;
}

.mensaje-cvs {
  margin-top: 5px;
  color: #aaa;
}

.mensaje-cvs::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.submit-section {
  display: flex;
  flex-direction: column;
}

.submit-section img {
  width: 75%;
  margin: 0 auto 15px auto;
}

.submit-section input {
  margin: 0 auto;
}

.product-name,
.product-total,
.cart-subtotal th,
.order-total th,
span.woocommerce-Price-currencySymbol,
bdi {
  color: black;
}

.test {
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.error {
  background-color: red;
}

.success {
  background-color: rgb(7, 160, 7);
}

.pending {
  background-color: yellow;
  color: black !important;
}

.acceder-holder {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 45px;
}

@media only screen and (max-width: 1024px) {
  .acceder-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px 0;
  }

  #tcoCCForm {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .datos-personales {
    grid-row-start: 1;
    grid-row-end: 2;
    height: -webkit-max-content;
    height: max-content;
  }

  .pedido {
    grid-row-start: 2;
    grid-row-end: 3;
    height: auto;
  }

  h3.pago-title,
  h3.pedido-title {
    font-size: 1.5rem;
  }

  h3.pedido-title {
    margin-bottom: 20px;
  }

  h3.pago-title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  select {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .datos-personales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-pago__fecha,
  .input-pago {
    margin: 0 auto 10px auto;
  }

  #ccNo,
  #cvv {
    height: 30px;
  }

  .mensaje-cvs {
    font-size: 4vw;
  }

  div.input-pago__fecha {
    flex-direction: column;
    align-items: center;
  }

  span.pago-option {
    font-size: 0.8rem;
  }

  .input-pago__fecha label {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-pago__fecha label br {
    display: none;
  }

  select {
    width: 45%;
  }

  .input-pago__fecha label div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submit-section {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .submit-section img {
    width: 45%;
    margin: 0;
  }

  .submit-section input {
    width: 45%;
    margin: 0;
  }
}

#iframe-container-bancard {
  height: auto;
  width: 100%;
  margin: auto;
}

iframe[src~="infonet"], 
iframe[src^="https://wpos.infonet"],
#iframe-container-bancard iframe {
  display: block !important;
}

.post-title {
  margin-bottom: 30px;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.container-publicaciones {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 70px;
  margin: 60px 0;
  padding: 0 100px;
}

.blog-container .post-content {
	margin: 0 auto;
	font-family: "Open Sans", sans-serif;
	padding: 0px;
  position: relative;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  column-gap: 50px;
  margin-top: 50px;
}

.blog-container main h2 {
  text-transform: uppercase;
}

.post-content p,
.post-content h2 {
  margin: 30px 0;
}

.post-meta {
  border-top: 1px solid #eaeaea;
  padding: 12px 0;
  font-size: 0.8rem;
  font-family: "lato", serif;
}

.post-meta svg {
  margin-right: 5px;
  color: var(--color-principal);
}

.post-content {
  margin: 30px 0;
  font-family: "Open Sans", sans-serif;
}
.post-content p {
  text-align: justify;
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 20px 0;
  text-transform: none !important;
}

.img-squared {
  width: 83px;
  height: 50px;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  z-index: 2;
}

.sidebar-decorator {
  height: 3px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 152, 215, 1) 35%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 41%,
    rgba(0, 0, 0, 1) 41%,
    rgba(0, 0, 0, 1) 100%
  );

  margin-bottom: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.card-container::after {
  width: 50px;
  height: 3px;
  background-color: var(--color-secundario);
  content: " ";
  margin-top: 30px;
}

.card-container::before {
  width: 700px;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  bottom: left;
  margin-top: 10px;
}

.post-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.post-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sidebar-post-item {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.sidebar-post-item:hover h2.title {
  color: var(--color-principal);
  transition: ease all 0.3s;
}
.sidebar-post-item h2.title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}

.title-blog {
  font-size: 1.5rem;
}

.title-blog::first-letter {
  text-transform: uppercase;
}

.fecha {
  width: 60px;
  height: 60px;
  border: 2px solid var(--color-terciario);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-bottom: 8px;
  margin-right: 10px;
}

.fecha__numero {
  color: var(--color-terciario);
  width: -webkit-max-content;
  width: max-content;
  height: 35px;
  margin: 0 auto;
  font-size: 2rem;
}

.fecha__iniciales {
  color: var(--color-terciario);
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  margin: 0 auto;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.content {
  display: flex;
  flex-direction: column;
}

.separador {
  width: 31px;
  height: 3px;
  background-color: var(--color-principal);
  margin-top: 30px;
}

.sidebar-section {
  margin: 60px 0;
}

.first-section-sidebar {
  margin-top: 0;
}

.sidebar-search {
  margin-top: 25px;
  background-color: #f0f2f5;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.sidebar-search input {
  border: none;
  height: 38px;
  width: 85%;
  padding-left: 20px;
}

.sidebar-search input:focus {
  outline: none;
}

.sidebar-search button {
  border: none;
  height: 38px;
  width: 10%;
  padding-right: 20px;
  padding-left: 5px;
  background-color: #fff;
}

.search-container {
  width: 70%;
  background-color: #fff;
}

.fa-search {
  color: var(--color-principal);
}

.blog {
  margin-left: 30px;
}

.font-black {
  margin-top: 20px;
  color: black;
  font-size: 1rem;
}

/*

    Pagination

*/

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}

.pagination li {
  margin-right: 10px;
}

.pagination > .active > a {
  background-color: var(--color-terciario);
  border-color: var(--color-terciario);
  color: #fff;
}
.pagination > li > a {
  border: 2px solid var(--color-terciario);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--color-terciario);
  border-color: var(--color-terciario);
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: var(--color-terciario);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media only screen and (max-width: 425px) {
  .container-publicaciones {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 60px auto;
  }

  .main-section {
    grid-template-columns: 1fr;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .title-blog {
    font-size: 1rem;
  }

  .fecha {
    width: 30px;
    height: 30px;
  }

  .fecha__numero {
    font-size: 1.2rem;
  }

  .fecha__iniciales {
    font-size: 0.8rem;
  }

  .font-black-blog {
    margin-top: 10px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 424px) and (min-width: 1px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .blog-container {
    display: block;
  }

  .sidebar-section {
    display: none;
  }

  .main-section {
    grid-template-columns: 1fr;
    grid-gap: 50px 0;
  }

  .container-publicaciones {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .pagination {
    justify-content: center;
  }

  ul.pagination li.previus a {
    font-size: 3vw;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  div div.content h2.title {
    font-size: 1.3rem;
  }
}

.social-share {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.social-share::before{
  content: '';
	display: block;
	position: absolute;
  margin-top: 10px;
  width: 35px;
  height: 3px;
  background-color: var(--color-principal);
  bottom: 0;
  left: 0;
}

.social-share::after
/* , .social-share::before */
{
  content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 1px;
	z-index: 10;
	left: 64px;
	background-color: #e1e2e4;
}

.social-share button {
  margin: 0 5px;
}

h4, .h4, blockquote {
	line-height: 26px;
	font-weight: 700;
	font-size: 20px;
}

::after, ::before {
	box-sizing: border-box;
}

.blog-container blockquote::before {
  top: -39px;
  margin-left: -37px;
}
.blog-container blockquote::after {
	bottom: -38px;
  margin-left: -37px;
}
.blog-container blockquote::after, 
.blog-container blockquote::before {
	content: '';
	display: block;
	position: relative;
	width: calc(100% + 37px);
	height: 1px;
	z-index: 10;
	left: 0;
	background-color: #e1e2e4;
}

/* blockquote::before {
	top: -24px;
} */

.blog-container blockquote ol:last-child, 
.blog-container blockquote p:last-child, 
.blog-container blockquote ul:last-child {
	margin-bottom: 0;
}

.blog-container main .post-content blockquote{
  border-color: var(--color-principal);
}

.blog-container blockquote {
	padding: 14px 35px 14px 37px;
	margin: 52px 0 47px;
	border-left: 3px solid #eab830;
}
/* .blog-container blockquote::after,
.blog-container blockquote::before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 1px;
	z-index: 10;
	left: 0;
	background-color: #e1e2e4;
} */

.blog-container div.post-content p:last-child {
	margin: 0px !important;
}
.blog-container main .post-content::before {
	width: 100%;
	height: 1px;
	background-color: #e1e2e4;
	bottom: 1px;
	left: 65px;
  margin-top: 10px;
}

.blog-container main .post-content::after{
  margin-top: 10px;
  width: 35px;
  height: 3px;
  background-color: var(--color-principal);
  bottom: 0;
  left: 0;
}

.blog-container main .post-content::after,
.blog-container main .post-content::before {
	content: '';
	display: block;
	position: absolute;
	z-index: 10;
}

.blog-container main .post-content ol {
	margin: 30px 0;
	padding-left: 25px;
}

.blog-container main .post-content ol li{
  margin-bottom: 10px;
}

.blog-container div.post-content > p:last-child {
	height: 48px;
}

.blog-container main .container-pagination {
	margin: 25px 0;
}

.sidebar__titulo--mayuscula::first-letter{
  text-transform: uppercase;
}
.breadcrumbs {
  background: #f0f0f0;
  color: #aaa;
  font-family: "Open Sans", sans-serif;
  font-size: 0.7rem;
  padding: 10px 15px;
  text-transform: uppercase;
}
.bc-list {
  list-style: none;
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
}

.bc-list li span {
  margin-left: 5px;
}

.bc-list li a {
  color: #aaa;
  text-transform: uppercase;
}

.input-group .nombre,
.input-group .email {
  width: 47%;
}

.svg.svg-inline--fa.fa-caret-left.fa-w-6 path {
  color: white;
}

button.btn-primary.button-nosotros-form {
  background-color: var(--color-principal);
}

.seccion-enviar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-nosotros-form {
  height: -webkit-max-content;
  height: max-content;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .input-group .nombre,
  .input-group .email {
    width: 100%;
    margin-bottom: 10px;
  }

  .seccion-enviar {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
  }

  .recaptcha {
    grid-row: 1 / 2;
  }

  .seccion-enviar button {
    grid-row: 2 / 3;
  }
}
/*
 Fix to iframe display none on Cursos.css
*/
.recaptcha iframe {
  display: block !important;
}

iframe[style="display: none;"] {
  display: none;
  background: red;
  display: none !important;
}

select[name="pais"] {
	width: 100%;
	border: white;
	color: #9b9c9e;
	background-color: #f0f2f5;
	padding-left: 10px;
}
.contacto-holder {
  margin: 0 auto;
  max-width: 600px;
}

.titulo-formulario {
  margin-bottom: 20px;
}

.post-content {
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  padding: 0 35px;
}

p.has-text-align-center {
  text-align: center !important;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.container div.post-content p:last-child {
  margin-bottom: 50px;
}

div.post-content p:not(.has-text-align-center) {
  margin: 15px 0;
}

div.post-content ul li {
  margin-left: 30px;
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
  /* margin: 0 10px; */
  width: -webkit-max-content;
  width: max-content;
}

.tarjeta-curso-info {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tarjeta-curso {
  width: 350px;
  min-height: 300px;
  background: white;
  margin: auto;
  border: 1px solid #eaeaea;
  transition: ease all 0.3s;
}

.invisible-link {
}

.tarjeta-curso:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso span {
  color: #999;
  text-align: center;
}

.tarjeta-curso img {
  width: 100%;
  object-fit: cover;
}

.tarjeta-curso h2.title {
  color: black;
  font-size: 1.3rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .tarjeta-curso img {
    height: 120px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1110px) {
  .tarjeta-curso {
    width: auto;
  }
}

.btn-inscribirse {
  margin-bottom: 10px;
}

.all-cursos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 100px auto 50px auto;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 50px;
  row-gap: 50px;
}
.curso-single {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  column-gap: 50px;
  margin: 50px auto;
}
.curso-single h2 {
  font-size: 2.2rem;
}
.meta-categorias {
  margin-top: 30px;
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
  align-items: center;
}
.meta-categorias h3 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #666;
}

.meta-categorias a {
  display: inline-block;
  margin-right: 5px;
  color: black;
  cursor: inherit;
}
.meta-categorias .icon {
  font-size: 2rem;
  color: var(--color-principal);
}

.single-tabs {
  width: 100%;
}

.single-tabs .react-tabs__tab {
  bottom: 0;
}
.single-tabs .react-tabs ul {
  background: #f0f4fa;
  color: #273044;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  font-weight: 700;
}

.single-tabs .react-tabs ul li {
  margin-right: 30px;
}
.single-tabs .react-tabs .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
  background: white;
}

.single-tabs .react-tabs__tab-panel {
  margin-top: 30px;
}
#div-detalle {
  margin-bottom: 50px;
}
#div-detalle p {
  margin: 0 0 20px;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
}

#div-detalle h2 {
  margin: 20px 0;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}
#div-detalle ul {
  margin-left: 20px;
  background: none;
}

#div-detalle ul li {
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  font-weight: 400;
}
.curriculum-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  column-gap: 20px;
  background: #f0f4fa;
  color: #273044;
  margin-bottom: 3px;
  padding: 10px;
  cursor: pointer;
}
.curriculum-row .curriculum-number {
  min-width: 30px;
  text-align: left;
  color: #999;
}
.curriculum-row .curriculum-icon {
  color: var(--color-principal);
}
.curriculum-row .curriculum-label {
  flex: 1 1;
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.curriculum-row .curriculum-label:hover {
  color: var(--color-principal);
}
.curriculum-row .curriculum-duration {
  color: #999;
}

.single-tabs .Collapsible {
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.single-tabs .Collapsible .Collapsible__trigger {
  padding: 20px 40px;
  display: block;
  cursor: pointer;
}

.single-tabs .Collapsible .Collapsible__trigger {
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.single-tabs .Collapsible .Collapsible__trigger.is-open {
  border-top: 3px solid var(--color-principal);
}

.single-tabs .Collapsible .Collapsible__contentInner {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  padding: 0 40px 20px 40px;
}
iframe.iframe-ocultar {
  display: none;
}

.curso-single .container-pagination {
	margin-top: 30px;
}

@media only screen and (max-width: 1200px) {
  .all-cursos {
    grid-template-columns: repeat(2, 1fr);
  }
}

aside a.btn-sidebar {
  display: block;
  text-align: center;
  color: white;
  background-color: var(--color-principal);
  /* border-radius: 10px; */
  width: 100%;
  text-transform: uppercase;
  font-family: Roboto, serif;
  padding: 12px 0;
  transition: ease all 0.3s;
}

aside a.btn-sidebar:hover {
  filter: brightness(1.2);
}

aside ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

aside ul li {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  font-family: Roboto, serif;
}

aside ul li .icon {
  font-size: 2rem;
}

aside ul li svg {
  color: var(--color-principal);
}

aside h2.title {
  color: var(--color-principal);
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 20px;
}

.cursos-populares {
  border: 1px solid #eaeaea;
  padding: 15px 30px 0 20px;
  margin: 50px 0;
}
.curso-popular-item {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
}
aside .curso-popular-item .title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .all-cursos {
    grid-template-columns: repeat(1, 1fr);
  }

  .curso-single {
    display: block;
  }

  .react-tabs__tab {
    display: block;
    padding: 20px;
  }

  .single-tabs .react-tabs ul li {
    margin-right: 0;
  }

  .single-tabs .react-tabs__tab {
    bottom: 0;
  }

  .react-tabs ul li {
    margin-right: 0;
  }
}

.tee {
  width: 100% !important;
}

.pagination {
	margin: 15px auto;
	display: flex;
	list-style: none;
	outline: none;
	justify-content: center;
}

.pagination li {
	margin-right: 10px;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
	border-radius: unset;
}

.pagination > li > a, .pagination > li > span {
	color: var(--color-terciario);
}

.pagination > li > a {
	border: 2px solid var(--color-terciario);
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
}
/* .carousel-item {
  width: 100%;
  height: 661px;
  background-image: url(../../images/Banner-principal.jpg);
  background-size: cover;
  background-position: center;
}

.banner-principal-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-principal-container__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0 10%;
}

.banner-principal-container__texto--principal {
  color: #fff;
  font-family: var(--tipografia-titulo);
  font-size: 2.8rem;
  font-weight: bold;
  width: 30rem;
  margin-bottom: 2rem;
}

.banner-principal-container__texto--secundario {
  color: #fff;
  font-size: 1.2rem;
  width: 30rem;
}

.banner-principal-container img {
  width: 100%;
}

.carousel-tag span.futuro {
  display: block;
  color: var(--color-principal);
}

.carousel-tag span.profesionales {
  font-size: 1rem;
}

@media only screen and (max-width: 380px) {
  .banner-principal-container__texto--principal {
    font-size: 7vw;
    text-align: center;
  }
}

@media only screen and (max-width: 425px) {
  .carousel-item {
    background-image: url("../../images/360x767-Mkt-Educ-.jpg");
    background-position: center;
    height: 767px;
  }

  .banner-principal-container__texto {
    padding: 30px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.5rem;
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .banner-principal-container__texto--secundario {
    font-size: 1rem;
    width: 100%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .banner-principal-container__texto--principal,
  .banner-principal-container__texto--secundario {
    width: 100%;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.3rem;
    margin-bottom: 30px;
  }

  .banner-principal-container {
    padding: 40px 30px 0;
  }

  .banner-principal-container__texto {
    padding: 0;
    justify-content: flex-start;
  }

  .carousel-item {
    background-position: left;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .banner-principal-container__texto {
    justify-content: flex-start;
    padding: 40px 5% 0;
  }

  .banner-principal-container__texto--principal {
    font-size: 2.3rem;
    margin-bottom: 30px;
    width: 100%;
  }

  .banner-principal-container__texto--secundario {
    width: 100%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 1024px) {
  .carousel-item {
    background-image: url("../../images/670x1024-Mkt-Educ-H-sintext.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 670px;
  }
}
 */

.carousel-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.carousel-tag {
  width: 30%;
  height: -webkit-max-content;
  height: max-content;
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  text-align: left;
  color: #fff;
}

.carousel-tag.tag-2 {
  width: 35%;
  font-size: 3.2rem;
  font-weight: bold;
  margin-left: 100px;
}

.carousel-tag.tag-1 {
  margin-left: 100px;
}

.letra-fina {
  font-size: 2.5rem;
  font-weight: 400;
}

.carusel-amarillo {
  font-size: 3.5rem;
  font-weight: 900;
  color: var(--color-secundario);
}

.carousel-tag p {
  color: #fff;
  margin-top: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.carousel-item:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.tag-1 img,
.tag-2 img {
  width: 100%;
}

.dnone {
	display: none !important;
}

.slider.animated li:nth-child(2) {
  display: none;
}

@media only screen and (max-width: 425px) {
  .carousel-tag.tag-1 {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    align-items: center;
  }

  .carousel-tag.tag-1 p {
    font-size: 1rem;
    width: 100%;
  }

  .carousel-tag {
    padding: 20px;
  }

  .carousel-tag.tag-2 {
    font-size: 1.3rem;
    margin-left: 10px;
  }

  .carusel-amarillo {
    font-size: 1.8rem;
  }

  .letra-fina {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .carousel-tag.tag-1 {
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
  }

  .carousel-tag.tag-1 p {
    width: 100%;
    font-size: 1rem;
  }

  .carousel-tag.tag-2 {
    margin-left: 20px;
  }

  .carousel-tag {
    padding: 20px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 460px) {
  .carousel-tag.tag-2 {
    font-size: 1rem;
  }

  .carusel-amarillo {
    font-size: 1.5rem;
  }

  .letra-fina {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 460px) and (max-width: 650px) {
  .carousel-tag.tag-2 {
    font-size: 1.3rem;
  }

  .carusel-amarillo {
    font-size: 1.8rem;
  }

  .letra-fina {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
  .carousel-tag.tag-2 {
    margin-left: 50px;
    font-size: 1.6rem;
  }

  .carusel-amarillo {
    font-size: 2rem;
  }

  .letra-fina {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-tag.tag-1 {
    font-size: 1.5rem;
  }

  .carousel-tag.tag-1 p {
    font-size: 1rem;
  }

  .carousel-tag.tag-2 {
    font-size: 1.5rem;
    margin-left: 60px;
  }

  .carusel-amarillo {
    font-size: 2rem;
  }

  .letra-fina {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1190px) {
  .carousel-tag.tag-1 {
    font-size: 1.8rem;
  }

  .carousel-tag.tag-1 p {
    font-size: 1.2rem;
  }

  .carousel-tag.tag-2 {
    font-size: 2rem;
    margin-left: 80px;
  }

  .carusel-amarillo {
    font-size: 2.5rem;
  }

  .letra-fina {
    font-size: 2.5rem;
  }
}

.custom-button-group {
  width: 100px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0 auto;
}

.custom-button-group button {
  cursor: pointer;
  width: 46px;
  height: 46px;
  line-height: 46px;
  color: white;
  background: var(--color-principal);
  border: none;
}

.carousel-parent {
  min-height: 350px;
  margin-top: 30px;
}

.carousel-parent h2.tipo-de-cursos {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 2rem;
}

/* ul.react-multi-carousel-track {
  height: 370px;
} */

.carousel-description {
  margin-bottom: 50px;
  font-weight: 500;
  padding: 0 100px;
  font-size: 1.1rem;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 425px) {
  ul.react-multi-carousel-track {
    height: 320px;
  }

  .container.carousel-home {
    margin: 40px 0 0;
    padding: 0;
    max-width: none;
    width: 100%;
  }

  .container.carousel-home.segundo-carusel {
    margin: 0;
  }

  .carousel-parent,
  .carousel-parent h2.tipo-de-cursos {
    margin-top: 0;
  }

  .carousel-parent {
    padding: 0 15px;
  }

  .carousel-parent h2.tipo-de-cursos {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .carousel-description {
    padding: 0;
    font-size: 1rem;
  }

  /*   .carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
}

@media only screen and (max-width: 768px) {
  .carousel-parent {
    margin: 0;
  }

  .carousel-description {
    padding: 0;
  }

  .carousel-parent h2.tipo-de-cursos {
    margin-top: 0;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1100px) {
  .react-multi-carousel-track {
    grid-column-gap: 20px;
    column-gap: 20px;
  }

  .react-multi-carousel-track li {
    width: auto;
  }
}

.carusel-profes h2 {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 2rem;
}

@media only screen and (max-width: 425px) {
  .carusel-profes
    .carousel-container
    .react-multi-carousel-list
    ul.react-multi-carousel-track {
    height: auto;
  }
}

.tarjeta-profe h3 {
  color: black;
  font-size: 1.3rem;
  text-align: center;
}

.tarjeta-profe .tarjeta-curso-info {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tarjeta-profe .tarjeta-curso-info h2 {
  margin: 20px 0;
}

@media only screen and (max-width: 425px) {
  div.tarjeta-curso img {
    height: auto;
    background-position: center;
  }
}

section {
  width: 100%;
}

.linea {
  display: none;
}

.condiciones-home {
  color: #fff;
}

.condiciones-home:visited {
  color: #fff;
}

.container-countdown {
  position: absolute;
  z-index: 1;
  top: calc(764px - 80px);
  width: -webkit-max-content;
  width: max-content;
  max-width: 95vw;
  height: -webkit-max-content;
  height: max-content;
}

.cta-home {
  font-family: "lato", serif;
  background: var(--color-principal);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 50px 70px;
  color: #171818;
  border-radius: 10px;
  grid-column-gap: 40px;
  column-gap: 40px;
  z-index: 3;
}

.cta-home h2 {
  width: 60%;
  font-size: 1.8rem;
  color: white;
}

.titulo-garantia {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 20%;
}

.cta-home h2 span {
  color: #eeba00;
}

.cta-home .countdown-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.cta-home .countdown-holder a {
  color: white;
  background: var(--color-secundario);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  transition: ease all 0.3s;
}

.cta-home .countdown-holder a:hover {
  filter: brightness(1.1);
}

.cta-home .countdown-holder a svg {
  margin-right: 20px;
}

.cta-home .countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.cta-home .countdown h2 {
  padding: 0;
  color: white;
  font-size: 2rem;
}

.cta-home .countdown span {
  font-size: 1.5rem;
  color: white;
}

section.first-section {
  width: 100%;
  margin: 200px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-section,
.content-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-section,
.first-section div.content-home {
  width: 40%;
  padding: 0 40px;
}

.video-section {
  align-items: center;
}

.video-section h2 {
  margin-bottom: 20px;
}

.video-player {
  width: 95%;
  border-radius: 10px;
  aspect-ratio: 16/9;
}

section.first-section .content-home p,
section.first-section .content-home h3 {
  width: -webkit-max-content;
  width: max-content;
  font-size: 1rem;
  margin-bottom: 15px;
}

section.first-section .content-home p:nth-child(2) {
  margin-bottom: 0;
}

section.first-section .content-home h3.text-azul {
  margin-bottom: 5px;
}

section.first-section .content-home h3 {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
}

section.first-section .content-home h3 .precio {
  font-weight: 800;
}

.content-home h3 {
  color: var(--color-principal);
  font-weight: bold;
  margin-bottom: 20px;
}

.boton-inscribirse {
  height: -webkit-max-content;
  height: max-content;
  border: none;
  min-width: -webkit-max-content;
  min-width: max-content;
  max-width: 40%;
  border-radius: 30px;
  padding: 15px 15px;
  background: var(--color-secundario);
  font-size: 1rem;
  font-weight: 700;
}

.content-home a {
  margin: 0 auto;
}

.boton-inscribirse:hover {
  cursor: pointer;
}

a .boton-inscribirse {
  color: black;
  font-size: 1rem;
  padding: 15px 70px;
}

a:hover .boton-inscribirse {
  color: #fff;
}

.content-home h3 span:not(:first-child) {
  color: red;
  text-decoration: line-through;
}

.second-section {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(/static/media/Banner-1.6e83b9d8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 30px 30px 800px;
}

.second-section__title {
  font-size: 2rem;
  font-weight: 700;
}

.second-section__subtitle {
  font-weight: 700;
}

.boton-inscribirse-2 {
  height: -webkit-max-content;
  height: max-content;
  border: 2px solid #171818;
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #ffc801;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto;
}

a:hover .boton-inscribirse-2 {
  border-color: #fff;
  cursor: pointer;
}

.boton-inscribirse-2 a {
  color: black;
}

a:hover .boton-inscribirse-2 {
  color: #fff;
}

.second-section p {
  margin-bottom: 20px;
}

.second-section a {
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
}

.cta-bg {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
  padding: 0 12%;
}

.cta-bg .texto-descuento-container {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.cta-bg .texto-descuento-container h3 {
  color: #fff;
  text-align: right;
  font-weight: 700;
  margin: 20px 0;
  font-size: 2.5rem;
  font-family: lato, sans-serif;
}

.texto-amarillo {
  color: var(--color-secundario);
}

.numero-grande {
  font-size: 7rem;
}

.texto-descuento-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 100px;
}

.cta-bg .texto-descuento-container p {
  color: #fff;
  font-weight: 400;
  text-align: right;
  font-size: 1.5rem;
}

.cta-bg .texto-descuento-container a button {
  background-color: var(--color-secundario);
  width: -webkit-max-content;
  width: max-content;
  margin: 20px 0;
  padding: 15px 30px;
  border: 2px solid transparent;
  border-radius: 30px;
}

.cta-bg .texto-descuento-container a:hover button {
  background-color: transparent;
  border: 2px solid #fff;
  cursor: pointer;
}

.cta-bg .texto-descuento-container a:hover button {
  color: var(--color-secundario);
}

.cta-bg .texto-descuento-container a button {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3rem;
}

.cta-bg .moving-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.cta-bg .moving-img img {
  width: 50%;
}

div.tarjeta-home:hover .content-home h3 {
  color: white;
}

div.tarjeta-home .content-home h3 {
  color: var(--color-principal);
}

div.tarjeta-home-amarillo .content-home h3 {
  color: #fff;
}

.porque-cdo .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.titulo-quinta-seccion {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  font-size: 2.3rem;
  font-weight: 800;
}

.tarjeta-home {
  background: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid var(--color-secundario);
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
}

.tarjeta-home:hover {
  background: var(--color-secundario);
  transition: ease all 0.3s;
}

.tarjeta-home:hover p {
  color: white;
}

.tarjeta-home svg {
  flex: 1 1;
  font-size: 2rem;
  color: var(--color-principal);
  margin-right: 20px;
}

.tarjeta-home:hover svg,
.tarjeta-home:hover h3 {
  color: white;
}
.tarjeta-home .content {
  padding-left: 20px;
  flex: 10 1;
}

.tarjeta-home .content h3 {
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home .content p {
  text-align: justify;
  margin-top: 20px;
}

.tarjeta-home-amarillo {
  background: var(--color-secundario);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid transparent;
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
  color: #fff;
}

.tarjeta-home-amarillo:hover {
  background: #fff;
  border-left: 6px solid var(--color-secundario);
  transition: ease all 0.3s;
}

.tarjeta-home-amarillo:hover p {
  color: #000000;
}

.tarjeta-home-amarillo svg {
  flex: 1 1;
  font-size: 2rem;
  color: #fff;
  margin-right: 20px;
}

.tarjeta-home-amarillo:hover svg,
div.tarjeta-home-amarillo:hover h3 {
  color: var(--color-principal);
}

.tarjeta-home-amarillo .content {
  padding-left: 20px;
  flex: 10 1;
}

.tarjeta-home-amarillo .content h3 {
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home-amarillo .content p {
  text-align: justify;
  margin-top: 20px;
}

.counters {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0 100px;
  padding: 0 5%;
}

.container-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  align-items: center;
  height: 100%;
}

.counter-items {
  height: 280px;
  color: white;
  text-align: center;
}
.counter-items svg {
  margin-top: 20px;
  font-size: 3rem;
}
.counter-items h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "lato", sans-serif;
  color: #fff;
}

.counter-items h4 {
  font-family: "lato", sans-serif;
  font-size: 1.5rem;
  color: white;
}

.docentes-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  column-gap: 20px;
  justify-content: center;
}

.docentes-section h2 {
  font-family: "lato", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.docente {
  text-align: center;
}
.docente h3 {
  margin: 20px 0;
  font-family: "lato", sans-serif;
}
.docente span {
  color: #999;
  display: block;
  margin-bottom: 20px;
}

.docentes-list img {
  width: 300px;
  height: 350px;
  object-fit: cover;
}

.garantia {
  width: 100%;
  height: 500px;
  margin-top: 50px;
  background-color: var(--color-principal);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.garantia h2 {
  font-size: 2rem;
  font-family: "lato", sans-serif;
  color: white;
}

.garantia ul {
  list-style: none;
  flex: 1 1;
}
.garantia .img-holder {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.garantia .img-holder img {
  width: 80%;
  margin: 0;
  padding: 0;
}

.garantia ul li {
  width: 20rem;
  margin-top: 50px;
  font-size: 1.3rem;
}

.garantia .container-garantia {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 140px;
  grid-column-gap: 90px;
  column-gap: 90px;
}

.seccion-informacion {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.garantia-column-one,
.garantia-column-two {
  padding: 0 10px;
  margin-bottom: 30px;
  width: 30%;
}

div.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imagen {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.imagen img {
  width: 100%;
  transform: rotate(15deg);
  position: relative;
  top: 125px;
  position: relative;
  z-index: 1;
}

h3.garantia-titulo {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}

.seccion-informacion p {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 5px;
  text-align: left;
}

.seccion-informacion span {
  font-style: italic;
  font-weight: 200;
}

.seccion-informacion {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.titulo-garantia img {
  width: 25%;
}

.orientado-container {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: #f4f4f4;
  padding: 70px 0;
}

.orientado-info-container {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}

.orientado-titulo {
  margin-bottom: 50px;
  text-align: center;
}

.orientado-info {
  width: 50%;
}

.orientado-info p {
  margin-bottom: 20px;
}

.orientado-info p:first-child {
  font-weight: bold;
}

.orientado-img {
  width: 50%;
}

.orientado-img img {
  width: 100%;
}

.boton-inscribirse-orientado {
  width: 50%;
  padding: 15px 50px;
  background-color: var(--color-secundario);
  border: none;
  border-radius: 20px;
  margin: 0 auto;
}

a .boton-inscribirse-orientado {
  font-weight: bold;
  color: black;
}

a:hover .boton-inscribirse-orientado {
  color: white;
}

.titulo-garantia h2 br {
  display: none;
}

.orientado-info a:hover button {
  cursor: pointer;
}

/**
  Fix a los videos extras de youtube
*/

.ytp-endscreen-content {
  display: none;
  visibility: hidden;
}

.html5-endscreen.ytp-player-content.videowall-endscreen.ytp-show-tiles {
  display: none;
  visibility: hidden;
}

@media only screen and (min-width: 391px) and (max-width: 392px) {
  .banner-principal-container__texto--principal {
    font-size: 2.6rem;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 392px) and (max-width: 422px) {
  .banner-principal-container__texto--principal {
    font-size: 2.7rem;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 422px) and (max-width: 424px) {
  .banner-principal-container__texto--principal {
    font-size: 2.71rem;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 424px) and (max-width: 425px) {
  .banner-principal-container__texto--principal {
    font-size: 2.72rem;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 425px) {
  .linea {
    display: flex;
    width: 75%;
    border-color: var(--color-secundario);
    margin: 30px 0;
  }

  h3.garantia-titulo {
    width: 100%;
    font-size: 1.8rem;
  }

  .garantia h2 {
    font-size: 1.8rem;
  }

  .titulo-garantia h2 br {
    display: block;
  }

  .video-section h2 {
    font-size: 1.3rem;
  }

  .cta-home .countdown-holder {
    grid-row-gap: 15px;
    row-gap: 15px;
    width: 100%;
  }

  div.countdown div {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 10px;
  }

  .video-section {
    width: 100%;
  }

  .video-player {
    width: 85%;
  }

  .second-section {
    padding: 15px 10px;
    height: auto;
    margin-top: 40px;
    background-position: right;
  }

  .second-section p {
    display: flex;
    font-size: 1rem;
  }

  .second-section p:first-child,
  .second-section p:nth-child(2) {
    margin: 0 auto 20px;
    font-size: 1.2rem;
    text-align: center;
  }

  .boton-inscribirse-2 {
    padding: 15px 50px;
    font-size: 1rem;
    max-width: none;
  }

  .cta-home {
    flex-direction: column;
    top: 90px;
    padding: 20px 15px;
  }

  .cta-home h2 {
    font-size: 1.1rem;
    width: 100%;
  }

  .cta-home .countdown {
    width: 100%;
    align-items: flex-end;
    margin-top: 10px;
    grid-column-gap: 10px;
    column-gap: 10px;
  }

  .cta-home .countdown-holder a {
    font-size: 0.8rem;
    padding: 15px 20px;
  }

  .cta-home .countdown span {
    font-size: 1rem;
    margin-top: 5px;
  }

  .cta-home .countdown h2 {
    font-size: 1.2rem;
  }

  section.first-section {
    margin-top: 200px;
    flex-direction: column;
    padding-bottom: 0;
  }

  .content-home {
    margin-top: 15px;
  }

  .content-home p br {
    display: none;
  }

  .content-home p:not(.suscripcion-texto) {
    text-align: center;
  }

  section.first-section .content-home {
    width: 100%;
    padding: 10px 10px 0;
  }

  .first-section .circle {
    padding-left: 0;
    margin: 35px auto 50px;
    width: 100%;
  }

  .boton-inscribirse {
    padding: 15px 50px;
  }

  .first-section .circle:before {
    width: 240px;
    height: 240px;
    right: 50px;
    left: 0;
  }

  .first-section .content-home h3 {
    color: var(--color-principal);
  }

  .cta-bg .moving-img img {
    display: none;
  }

  section.porque-cdo {
    width: 100%;
    padding: 0 10px;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 100%;
  }

  .tarjeta-home svg,
  .tarjeta-home-amarillo svg {
    margin: 0 auto;
  }

  div.content-home {
    height: 80%;
  }

  .tarjeta-home {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover p {
    color: #fff;
  }

  .tarjeta-home-amarillo:hover svg,
  .tarjeta-home svg {
    color: #fff;
  }

  div.content-home h3,
  div.content-home p {
    font-size: 0.8rem;
    color: #fff;
  }

  .docente {
    margin-bottom: 20px;
  }

  .container-items {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  .counter-items svg {
    font-size: 2rem;
  }

  .counter-items h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .counter-items h4 {
    font-size: 1rem;
  }

  .container.docentes-section {
    margin: 0;
    padding: 0;
  }

  .docentes-list {
    flex-direction: column;
  }

  .docente h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  div.docente p {
    font-size: 0.8rem;
  }

  .cta-bg {
    padding: 70px 15px 0;
    margin: 0;
    height: 350px;
    width: 100%;
    background-position: right;
    display: flex;
    align-items: center;
  }

  .titulo-quinta-seccion {
    font-size: 1.5rem;
    margin: 30px 0;
    text-align: center;
  }

  .cta-bg .flex {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .cta-bg .texto-descuento-container {
    width: 100%;
    margin: 0;
  }

  .texto-amarillo {
    left: 0;
  }

  .cta-bg .texto-descuento-container h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  .cta-bg .texto-descuento-container p {
    font-size: 1rem;
  }

  .cta-bg .texto-descuento-container a button {
    padding: 10px 20px;
  }

  .cta-bg .texto-descuento-container button a {
    font-size: 0.8rem;
  }

  .numero-grande {
    font-size: 3rem;
    left: 15px;
  }

  .porcentaje {
    font-size: 1.4rem;
    right: 0;
  }

  .numero-pequeño {
    right: 0;
    font-size: 1.3rem;
  }

  .container-garantia {
    width: 100%;
  }

  .orientado-container {
    margin-top: 25px;
    padding: 30px 15px;
  }

  h2.orientado-titulo {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .orientado-info p br {
    display: none;
  }

  .orientado-img {
    display: none;
  }

  .counters {
    height: auto;
    padding: 30px 0;
    margin-bottom: 40px;
  }

  .seccion-vacia {
    display: none;
  }

  .garantia-column-one,
  .garantia-column-two {
    width: 100%;
  }

  .seccion-informacion {
    flex-direction: column;
    width: 100%;
  }

  .garantia-column-one,
  .garantia-column-two {
    padding: 0;
  }

  .garantia .img-holder {
    display: none;
  }

  .garantia ul li {
    margin-top: 30px;
  }

  .imagen {
    display: none;
  }
}

@media only screen and (max-width: 465px) and (min-width: 425px) {
  .container-countdown {
    top: calc(764px - 100px);
  }

  .video-section {
    width: 100%;
  }

  .cta-home h2 {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 466px) and (min-width: 465px) {
  .container-countdown {
    top: calc(764px - 150px);
  }

  .video-section {
    margin-top: 240px;
  }

  .cta-home h2 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 500px) and (min-width: 466px) {
  .cta-home h2 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 600px) and (min-width: 466px) {
  .container-countdown {
    top: calc(764px - 150px);
  }

  .video-section {
    width: 100%;
  }
}

@media only screen and (max-width: 506px) and (min-width: 425px) {
  .cta-bg .texto-descuento-container {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 506px) and (max-width: 788px) {
  .cta-bg .texto-descuento-container {
    margin-top: 120px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .orientado-container {
    padding: 40px 0;
    margin-top: 40px;
  }

  h2.orientado-titulo {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .garantia h2 {
    display: inline-block;
  }

  .garantia {
    padding: 50px 0;
    height: -webkit-max-content;
    height: max-content;
    justify-content: center;
  }

  .seccion-informacion {
    width: 100%;
  }

  .orientado-img,
  .orientado-img img,
  .imagen {
    display: none;
  }

  .titulo-garantia {
    width: 100%;
    display: flex;
    justify-content: center;
    position: static;
    margin-top: 25px;
  }

  .seccion-informacion p br {
    display: none;
  }

  .garantia .container-garantia {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    align-items: center;
  }

  .boton-inscribirse-orientado {
    width: -webkit-max-content;
    width: max-content;
  }

  .boton-inscribirse-orientado {
    width: -webkit-max-content;
    width: max-content;
  }

  .orientado-info-container {
    flex-direction: column;
    height: -webkit-max-content;
    height: max-content;
  }

  .orientado-info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .orientado-info a {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto;
  }

  .orientado-info p {
    width: 100%;
    text-align: left;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 15px;
    width: 100%;
    font-size: 1rem;
    text-align: left;
  }

  section.first-section .content-home p {
    font-size: 1rem;
    color: black;
  }

  .titulo-quinta-seccion {
    text-align: center;
  }

  .tarjeta-home {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo,
  .tarjeta-home {
    flex-direction: column;
    align-items: center;
    height: -webkit-max-content;
    height: max-content;
  }

  div.tarjeta-home-amarillo .content-home h3,
  div.tarjeta-home .content-home h3 {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }

  .content-home p {
    text-align: center;
    color: #fff;
  }

  .tarjeta-home-amarillo svg,
  .tarjeta-home svg {
    color: #fff;
    margin: 0 auto;
  }

  .content-home {
    text-align: center;
    height: 80%;
  }

  .tarjeta-home-amarillo:hover .content-home p {
    color: #fff;
  }

  .tarjeta-home-amarillo:hover {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover svg {
    color: #fff;
  }
}

@media only screen and (max-width: 600px) and (min-width: 425px) {
  .numero-grande {
    font-size: 4rem;
  }

  .counter-items h4 {
    font-size: 0.8rem;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 10px;
    width: 100%;
  }

  section.first-section .content-home p {
    font-size: 1rem;
  }

  .first-section .circle:before {
    display: block;
    width: 250px;
    height: 250px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 6%;
    top: 20px;
  }

  .cta-home .countdown span {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0;
  }

  .boton-inscribirse-2 {
    min-width: none;
    max-width: none;
    width: -webkit-max-content;
    width: max-content;
  }

  .numero-pequeño {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container button a {
    font-size: 1rem;
  }

  .tarjeta-home .content p {
    font-size: 1rem;
  }

  .second-section p br,
  .content-home p br {
    display: none;
  }

  .container-items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  section.counters svg:not(:root).svg-inline--fa {
    margin-top: 0;
    width: 10vw;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 5px;
    color: white;
  }

  .counter-items h3 {
    margin: 5px 0;
  }

  section.docentes-section {
    width: 100%;
    margin: 0;
  }

  .docente h3 {
    font-size: 6vw;
    margin: 10px 0;
  }

  div.docente p {
    font-size: 1.5rem;
  }

  .garantia h2 {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container h3 {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container p {
    font-size: 1.4rem;
  }

  .docentes-list {
    display: block;
    padding: 0 20px;
  }

  .counter-items h3 {
    font-size: 1.5rem;
  }
  .counter-items h4 {
    font-size: 1.2rem;
  }

  .cta-bg .flex {
    display: block;
  }

  .first-section {
    display: block;
  }

  .first-section .circle:before {
    display: block;
    width: 300px;
    height: 300px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 20px;
  }

  .first-section .circle span {
    color: var(--color-principal);
  }

  .docente h3 {
    font-size: 3vw;
  }

  div.docente {
    margin-bottom: 40px;
  }

  section.garantia {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  ul.garantia-column-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-two {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-one,
  ul.garantia-column-two {
    margin-bottom: 0;
  }

  .garantia .img-holder {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .garantia .img-holder img {
    display: none;
  }

  footer .blog .entry .img {
    flex: none;
  }

  .garantia ul li {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .docentes-list img {
    width: 80%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 640px) {
  .cta-home h2 {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
  }

  .container-countdown {
    top: 480px;
  }

  .cta-home .countdown-holder a {
    padding: 15px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .cta-home h2 {
    font-size: 1.8rem;
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .linea {
    display: flex;
    width: 65%;
    background-color: var(--color-secundario);
    border-color: var(--color-secundario);
    margin: 30px 0;
  }

  .porcentaje,
  .numero-pequeño,
  .texto-amarillo {
    position: static;
  }

  .porcentaje {
    background-color: transparent;
  }
}

@media only screen and (min-width: 425px) and (max-width: 1024px) {
  .numero-grande {
    font-size: 9rem;
  }

  .porcentaje {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .porque-cdo .grid {
    grid-template-columns: 1fr;
  }

  section.cta-bg {
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 50px;
    margin-top: 0;
  }

  .second-section__title,
  .second-section__subtitle {
    text-align: center;
    width: 100%;
  }

  .second-section {
    height: auto;
    padding: 20px 40px;
    margin: 40px 0;
    background-position: right;
  }

  section.first-section .content-home h3 {
    font-size: 1.5rem;
  }

  .video-section {
    margin-bottom: 30px;
    width: 100%;
  }

  .first-section div.content-home {
    width: 100%;
    padding: 0 40px 0;
  }

  div.countdown div {
    margin: 0;
  }

  .cta-home .countdown h2 {
    padding: 0;
    color: white;
    font-size: 1.3rem;
  }

  .cta-home .countdown {
    width: 100%;
    grid-column-gap: 20px;
    column-gap: 20px;
  }

  .cta-home .countdown-holder a {
    margin: 0;
  }

  .cta-home .countdown-holder {
    padding: 0 20px;
    margin-top: 20px;
    width: 100%;
  }

  section.first-section {
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-player {
    width: 80%;
  }

  .first-section .content p {
    font-size: 1.3rem;
  }

  .first-section .content h3 {
    font-size: 1.6rem;
  }

  .first-section .circle {
    padding: 50px 0 0 25px;
    margin-bottom: 50px;
  }

  .container-countdown {
    width: 80%;
    max-width: none;
  }

  .cta-home {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0;
  }
}

/* @media only screen and (min-width: 600px) and (max-width: 640px) {
  .video-section {
    margin-top: 230px;
  }
} */

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .video-section {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .tarjeta-home,
  .tarjeta-home-amarillo {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .tarjeta-home,
  .tarjeta-home-amarillo {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 20%;
  }
}

@media only screen and (min-width: 821px) and (max-width: 900px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 25%;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 30%;
  }
}

@media only screen and (min-width: 737px) and (max-width: 1024px) {
  .first-section div.content-home {
    padding: 0 120px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .video-section,
  .first-section div.content-home {
    width: 100%;
  }

  .content-home {
    width: 100%;
    padding: 0 40px 0;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    width: 100%;
  }

  .garantia h2 {
    width: -webkit-max-content;
    width: max-content;
  }

  .imagen img,
  .imagen {
    display: none;
  }

  .seccion-informacion {
    width: 100%;
  }

  .second-section {
    padding: 30px 130px;
    margin-top: 50px;
    background-position-x: right;
  }

  section.first-section {
    flex-direction: column;
    margin-top: 330px;
  }

  .cta-home {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .video-section {
    margin-bottom: 30px;
  }

  .second-section__title,
  .second-section__subtitle {
    text-align: center;
  }

  .video-player {
    width: 70%;
  }

  .cta-bg {
    padding: 0 5%;
    background-position: right;
  }

  .orientado-info p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .orientado-container {
    padding: 70px 30px;
  }

  .boton-inscribirse-orientado {
    width: -webkit-max-content;
    width: max-content;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .cta-bg {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1140px) {
  .second-section {
    padding-left: 400px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1141px) and (max-width: 1200px) {
  .second-section {
    padding-left: 450px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .second-section {
    padding-left: 500px;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .second-section {
    padding-left: 600px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1220px) {
  .video-section,
  .content-home {
    width: 50%;
  }

  .first-section {
    padding: 0 30px;
    grid-column-gap: 0;
    column-gap: 0;
  }

  .video-player {
    width: 100%;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    width: 100%;
  }

  section.first-section .content-home p br {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1169px) {
  .titulo-garantia {
    margin-bottom: 20px;
  }

  .garantia h2 {
    width: -webkit-max-content;
    width: max-content;
  }

  .garantia h2 br {
    display: none;
  }

  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

@media only screen and (min-width: 1169px) and (max-width: 1360px) {
  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

@media only screen and (min-width: 1245px) and (max-width: 1440px) {
  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

/* Boton de Bitrix */
.b24-widget-button-position-bottom-right {
  right: 27px !important;
  bottom: 100px !important;
}

@media only screen and (max-width: 365px) {
  .container-countdown {
    top: 800px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 390px) {
  .container-countdown {
    top: 850px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 425px) {
  .container-countdown {
    top: 920px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 640px) {
  .container-countdown {
    top: 550px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .container-countdown {
    top: 590px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cta-home h2,
  .cta-home .countdown-holder,
  .cta-home .countdown-holder a {
    width: 100%;
  }

  .cta-home {
    padding: 30px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .container-countdown {
    top: 520px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .container-countdown {
    top: 520px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .container-countdown {
    top: 540px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .container-countdown {
    top: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1325px) {
  .container-countdown {
    top: 640px;
  }
}

.post-content {
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  padding: 0 35px;
}

p.has-text-align-center {
  text-align: center !important;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.container div.post-content p:last-child {
  margin-bottom: 50px;
}

div.post-content p:not(.has-text-align-center) {
  margin: 15px 0;
}

div.post-content ul li {
  margin-left: 30px;
}

main {
  width: 100%;
}

.questions-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 100px auto;
}

.section-title-questions {
  font-family: Roboto;
  /*font-size: 2vw;*/
  font-weight: 800;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 2rem;
}

h3 {
  font-family: Roboto;
  /*font-size: 1.1vw;*/
  font-weight: 500;
  margin: 0;
  color: #0099d5;
}

.question h3 {
  color: var(--color-principal);
  font-size: 1rem;
  text-align: left;
}

.responses {
  font-family: Roboto;
  margin: 20px 0 15px 0;
  display: none;
}

.responses.mostrar {
  font-family: Roboto;
  display: block;
  height: -webkit-max-content;
  height: max-content;
}

.responses p {
  font-size: 0.9rem;
  text-align: left;
}

.ocultar {
  display: none;
}

.question {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 15px 0;
}

.questions-button {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  height: -webkit-max-content;
  height: max-content;
  padding: 0 25px;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12 path {
  color: var(--color-principal);
  transition: all 0.8s ease;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12.icon-preguntas path {
  transform: rotate(90deg);
  transition: all 0.8s ease;
}

.questions-button-initial-state {
  height: 40px;
}

.subrayado {
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.enlace-cursos {
  color: var(--color-principal);
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

@media only screen and (max-width: 425px) {
  .question h3 {
    font-size: 1rem;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  aside {
    display: none;
  }

  .pagination {
    justify-content: center;
  }

  .section-title-questions {
    font-size: 5vw;
  }

  .questions-section p {
    font-size: 0.9rem;
  }

  .questions-section {
    margin: 30px auto;
    width: 90%;
  }

  .question svg.icon-preguntas {
    width: 3vw;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 426px) {
  .questions-section {
    width: 85%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

