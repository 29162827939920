main {
  width: 100%;
}

.questions-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 100px auto;
}

.section-title-questions {
  font-family: Roboto;
  /*font-size: 2vw;*/
  font-weight: 800;
  height: max-content;
  margin-bottom: 2rem;
}

h3 {
  font-family: Roboto;
  /*font-size: 1.1vw;*/
  font-weight: 500;
  margin: 0;
  color: #0099d5;
}

.question h3 {
  color: var(--color-principal);
  font-size: 1rem;
  text-align: left;
}

.responses {
  font-family: Roboto;
  margin: 20px 0 15px 0;
  display: none;
}

.responses.mostrar {
  font-family: Roboto;
  display: block;
  height: max-content;
}

.responses p {
  font-size: 0.9rem;
  text-align: left;
}

.ocultar {
  display: none;
}

.question {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 15px 0;
}

.questions-button {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  height: max-content;
  padding: 0 25px;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12 path {
  color: var(--color-principal);
  transition: all 0.8s ease;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12.icon-preguntas path {
  transform: rotate(90deg);
  transition: all 0.8s ease;
}

.questions-button-initial-state {
  height: 40px;
}

.subrayado {
  text-decoration: dashed;
}

.enlace-cursos {
  color: var(--color-principal);
  text-decoration: dashed;
}

@media only screen and (max-width: 425px) {
  .question h3 {
    font-size: 1rem;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  aside {
    display: none;
  }

  .pagination {
    justify-content: center;
  }

  .section-title-questions {
    font-size: 5vw;
  }

  .questions-section p {
    font-size: 0.9rem;
  }

  .questions-section {
    margin: 30px auto;
    width: 90%;
  }

  .question svg.icon-preguntas {
    width: 3vw;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 426px) {
  .questions-section {
    width: 85%;
  }
}
