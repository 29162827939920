section {
  width: 100%;
}

.linea {
  display: none;
}

.condiciones-home {
  color: #fff;
}

.condiciones-home:visited {
  color: #fff;
}

.container-countdown {
  position: absolute;
  z-index: 1;
  top: calc(764px - 80px);
  width: max-content;
  max-width: 95vw;
  height: max-content;
}

.cta-home {
  font-family: "lato", serif;
  background: var(--color-principal);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 50px 70px;
  color: #171818;
  border-radius: 10px;
  column-gap: 40px;
  z-index: 3;
}

.cta-home h2 {
  width: 60%;
  font-size: 1.8rem;
  color: white;
}

.titulo-garantia {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 20%;
}

.cta-home h2 span {
  color: #eeba00;
}

.cta-home .countdown-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  row-gap: 20px;
}

.cta-home .countdown-holder a {
  color: white;
  background: var(--color-secundario);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  transition: ease all 0.3s;
}

.cta-home .countdown-holder a:hover {
  filter: brightness(1.1);
}

.cta-home .countdown-holder a svg {
  margin-right: 20px;
}

.cta-home .countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
  column-gap: 20px;
}

.cta-home .countdown h2 {
  padding: 0;
  color: white;
  font-size: 2rem;
}

.cta-home .countdown span {
  font-size: 1.5rem;
  color: white;
}

section.first-section {
  width: 100%;
  margin: 200px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-section,
.content-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-section,
.first-section div.content-home {
  width: 40%;
  padding: 0 40px;
}

.video-section {
  align-items: center;
}

.video-section h2 {
  margin-bottom: 20px;
}

.video-player {
  width: 95%;
  border-radius: 10px;
  aspect-ratio: 16/9;
}

section.first-section .content-home p,
section.first-section .content-home h3 {
  width: max-content;
  font-size: 1rem;
  margin-bottom: 15px;
}

section.first-section .content-home p:nth-child(2) {
  margin-bottom: 0;
}

section.first-section .content-home h3.text-azul {
  margin-bottom: 5px;
}

section.first-section .content-home h3 {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
}

section.first-section .content-home h3 .precio {
  font-weight: 800;
}

.content-home h3 {
  color: var(--color-principal);
  font-weight: bold;
  margin-bottom: 20px;
}

.boton-inscribirse {
  height: max-content;
  border: none;
  min-width: max-content;
  max-width: 40%;
  border-radius: 30px;
  padding: 15px 15px;
  background: var(--color-secundario);
  font-size: 1rem;
  font-weight: 700;
}

.content-home a {
  margin: 0 auto;
}

.boton-inscribirse:hover {
  cursor: pointer;
}

a .boton-inscribirse {
  color: black;
  font-size: 1rem;
  padding: 15px 70px;
}

a:hover .boton-inscribirse {
  color: #fff;
}

.content-home h3 span:not(:first-child) {
  color: red;
  text-decoration: line-through;
}

.second-section {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../../images/Banner-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 30px 30px 800px;
}

.second-section__title {
  font-size: 2rem;
  font-weight: 700;
}

.second-section__subtitle {
  font-weight: 700;
}

.boton-inscribirse-2 {
  height: max-content;
  border: 2px solid #171818;
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #ffc801;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto;
}

a:hover .boton-inscribirse-2 {
  border-color: #fff;
  cursor: pointer;
}

.boton-inscribirse-2 a {
  color: black;
}

a:hover .boton-inscribirse-2 {
  color: #fff;
}

.second-section p {
  margin-bottom: 20px;
}

.second-section a {
  width: max-content;
  margin: 0 auto;
}

.cta-bg {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
  padding: 0 12%;
}

.cta-bg .texto-descuento-container {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.cta-bg .texto-descuento-container h3 {
  color: #fff;
  text-align: right;
  font-weight: 700;
  margin: 20px 0;
  font-size: 2.5rem;
  font-family: lato, sans-serif;
}

.texto-amarillo {
  color: var(--color-secundario);
}

.numero-grande {
  font-size: 7rem;
}

.texto-descuento-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 100px;
}

.cta-bg .texto-descuento-container p {
  color: #fff;
  font-weight: 400;
  text-align: right;
  font-size: 1.5rem;
}

.cta-bg .texto-descuento-container a button {
  background-color: var(--color-secundario);
  width: max-content;
  margin: 20px 0;
  padding: 15px 30px;
  border: 2px solid transparent;
  border-radius: 30px;
}

.cta-bg .texto-descuento-container a:hover button {
  background-color: transparent;
  border: 2px solid #fff;
  cursor: pointer;
}

.cta-bg .texto-descuento-container a:hover button {
  color: var(--color-secundario);
}

.cta-bg .texto-descuento-container a button {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3rem;
}

.cta-bg .moving-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.cta-bg .moving-img img {
  width: 50%;
}

div.tarjeta-home:hover .content-home h3 {
  color: white;
}

div.tarjeta-home .content-home h3 {
  color: var(--color-principal);
}

div.tarjeta-home-amarillo .content-home h3 {
  color: #fff;
}

.porque-cdo .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.titulo-quinta-seccion {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  font-size: 2.3rem;
  font-weight: 800;
}

.tarjeta-home {
  background: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid var(--color-secundario);
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
}

.tarjeta-home:hover {
  background: var(--color-secundario);
  transition: ease all 0.3s;
}

.tarjeta-home:hover p {
  color: white;
}

.tarjeta-home svg {
  flex: 1;
  font-size: 2rem;
  color: var(--color-principal);
  margin-right: 20px;
}

.tarjeta-home:hover svg,
.tarjeta-home:hover h3 {
  color: white;
}
.tarjeta-home .content {
  padding-left: 20px;
  flex: 10;
}

.tarjeta-home .content h3 {
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home .content p {
  text-align: justify;
  margin-top: 20px;
}

.tarjeta-home-amarillo {
  background: var(--color-secundario);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid transparent;
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
  color: #fff;
}

.tarjeta-home-amarillo:hover {
  background: #fff;
  border-left: 6px solid var(--color-secundario);
  transition: ease all 0.3s;
}

.tarjeta-home-amarillo:hover p {
  color: #000000;
}

.tarjeta-home-amarillo svg {
  flex: 1;
  font-size: 2rem;
  color: #fff;
  margin-right: 20px;
}

.tarjeta-home-amarillo:hover svg,
div.tarjeta-home-amarillo:hover h3 {
  color: var(--color-principal);
}

.tarjeta-home-amarillo .content {
  padding-left: 20px;
  flex: 10;
}

.tarjeta-home-amarillo .content h3 {
  font-family: "lato", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home-amarillo .content p {
  text-align: justify;
  margin-top: 20px;
}

.counters {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0 100px;
  padding: 0 5%;
}

.container-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  align-items: center;
  height: 100%;
}

.counter-items {
  height: 280px;
  color: white;
  text-align: center;
}
.counter-items svg {
  margin-top: 20px;
  font-size: 3rem;
}
.counter-items h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "lato", sans-serif;
  color: #fff;
}

.counter-items h4 {
  font-family: "lato", sans-serif;
  font-size: 1.5rem;
  color: white;
}

.docentes-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: center;
}

.docentes-section h2 {
  font-family: "lato", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.docente {
  text-align: center;
}
.docente h3 {
  margin: 20px 0;
  font-family: "lato", sans-serif;
}
.docente span {
  color: #999;
  display: block;
  margin-bottom: 20px;
}

.docentes-list img {
  width: 300px;
  height: 350px;
  object-fit: cover;
}

.garantia {
  width: 100%;
  height: 500px;
  margin-top: 50px;
  background-color: var(--color-principal);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.garantia h2 {
  font-size: 2rem;
  font-family: "lato", sans-serif;
  color: white;
}

.garantia ul {
  list-style: none;
  flex: 1;
}
.garantia .img-holder {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.garantia .img-holder img {
  width: 80%;
  margin: 0;
  padding: 0;
}

.garantia ul li {
  width: 20rem;
  margin-top: 50px;
  font-size: 1.3rem;
}

.garantia .container-garantia {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 140px;
  column-gap: 90px;
}

.seccion-informacion {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.garantia-column-one,
.garantia-column-two {
  padding: 0 10px;
  margin-bottom: 30px;
  width: 30%;
}

div.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imagen {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.imagen img {
  width: 100%;
  transform: rotate(15deg);
  position: relative;
  top: 125px;
  position: relative;
  z-index: 1;
}

h3.garantia-titulo {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}

.seccion-informacion p {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 5px;
  text-align: left;
}

.seccion-informacion span {
  font-style: italic;
  font-weight: 200;
}

.seccion-informacion {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.titulo-garantia img {
  width: 25%;
}

.orientado-container {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: #f4f4f4;
  padding: 70px 0;
}

.orientado-info-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.orientado-titulo {
  margin-bottom: 50px;
  text-align: center;
}

.orientado-info {
  width: 50%;
}

.orientado-info p {
  margin-bottom: 20px;
}

.orientado-info p:first-child {
  font-weight: bold;
}

.orientado-img {
  width: 50%;
}

.orientado-img img {
  width: 100%;
}

.boton-inscribirse-orientado {
  width: 50%;
  padding: 15px 50px;
  background-color: var(--color-secundario);
  border: none;
  border-radius: 20px;
  margin: 0 auto;
}

a .boton-inscribirse-orientado {
  font-weight: bold;
  color: black;
}

a:hover .boton-inscribirse-orientado {
  color: white;
}

.titulo-garantia h2 br {
  display: none;
}

.orientado-info a:hover button {
  cursor: pointer;
}

/**
  Fix a los videos extras de youtube
*/

.ytp-endscreen-content {
  display: none;
  visibility: hidden;
}

.html5-endscreen.ytp-player-content.videowall-endscreen.ytp-show-tiles {
  display: none;
  visibility: hidden;
}

@media only screen and (min-width: 391px) and (max-width: 392px) {
  .banner-principal-container__texto--principal {
    font-size: 2.6rem;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 392px) and (max-width: 422px) {
  .banner-principal-container__texto--principal {
    font-size: 2.7rem;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 422px) and (max-width: 424px) {
  .banner-principal-container__texto--principal {
    font-size: 2.71rem;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 424px) and (max-width: 425px) {
  .banner-principal-container__texto--principal {
    font-size: 2.72rem;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 425px) {
  .linea {
    display: flex;
    width: 75%;
    border-color: var(--color-secundario);
    margin: 30px 0;
  }

  h3.garantia-titulo {
    width: 100%;
    font-size: 1.8rem;
  }

  .garantia h2 {
    font-size: 1.8rem;
  }

  .titulo-garantia h2 br {
    display: block;
  }

  .video-section h2 {
    font-size: 1.3rem;
  }

  .cta-home .countdown-holder {
    row-gap: 15px;
    width: 100%;
  }

  div.countdown div {
    width: max-content;
    margin: 0 10px;
  }

  .video-section {
    width: 100%;
  }

  .video-player {
    width: 85%;
  }

  .second-section {
    padding: 15px 10px;
    height: auto;
    margin-top: 40px;
    background-position: right;
  }

  .second-section p {
    display: flex;
    font-size: 1rem;
  }

  .second-section p:first-child,
  .second-section p:nth-child(2) {
    margin: 0 auto 20px;
    font-size: 1.2rem;
    text-align: center;
  }

  .boton-inscribirse-2 {
    padding: 15px 50px;
    font-size: 1rem;
    max-width: none;
  }

  .cta-home {
    flex-direction: column;
    top: 90px;
    padding: 20px 15px;
  }

  .cta-home h2 {
    font-size: 1.1rem;
    width: 100%;
  }

  .cta-home .countdown {
    width: 100%;
    align-items: flex-end;
    margin-top: 10px;
    column-gap: 10px;
  }

  .cta-home .countdown-holder a {
    font-size: 0.8rem;
    padding: 15px 20px;
  }

  .cta-home .countdown span {
    font-size: 1rem;
    margin-top: 5px;
  }

  .cta-home .countdown h2 {
    font-size: 1.2rem;
  }

  section.first-section {
    margin-top: 200px;
    flex-direction: column;
    padding-bottom: 0;
  }

  .content-home {
    margin-top: 15px;
  }

  .content-home p br {
    display: none;
  }

  .content-home p:not(.suscripcion-texto) {
    text-align: center;
  }

  section.first-section .content-home {
    width: 100%;
    padding: 10px 10px 0;
  }

  .first-section .circle {
    padding-left: 0;
    margin: 35px auto 50px;
    width: 100%;
  }

  .boton-inscribirse {
    padding: 15px 50px;
  }

  .first-section .circle:before {
    width: 240px;
    height: 240px;
    right: 50px;
    left: 0;
  }

  .first-section .content-home h3 {
    color: var(--color-principal);
  }

  .cta-bg .moving-img img {
    display: none;
  }

  section.porque-cdo {
    width: 100%;
    padding: 0 10px;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 100%;
  }

  .tarjeta-home svg,
  .tarjeta-home-amarillo svg {
    margin: 0 auto;
  }

  div.content-home {
    height: 80%;
  }

  .tarjeta-home {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover p {
    color: #fff;
  }

  .tarjeta-home-amarillo:hover svg,
  .tarjeta-home svg {
    color: #fff;
  }

  div.content-home h3,
  div.content-home p {
    font-size: 0.8rem;
    color: #fff;
  }

  .docente {
    margin-bottom: 20px;
  }

  .container-items {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .counter-items {
    height: max-content;
  }

  .counter-items svg {
    font-size: 2rem;
  }

  .counter-items h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .counter-items h4 {
    font-size: 1rem;
  }

  .container.docentes-section {
    margin: 0;
    padding: 0;
  }

  .docentes-list {
    flex-direction: column;
  }

  .docente h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  div.docente p {
    font-size: 0.8rem;
  }

  .cta-bg {
    padding: 70px 15px 0;
    margin: 0;
    height: 350px;
    width: 100%;
    background-position: right;
    display: flex;
    align-items: center;
  }

  .titulo-quinta-seccion {
    font-size: 1.5rem;
    margin: 30px 0;
    text-align: center;
  }

  .cta-bg .flex {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .cta-bg .texto-descuento-container {
    width: 100%;
    margin: 0;
  }

  .texto-amarillo {
    left: 0;
  }

  .cta-bg .texto-descuento-container h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  .cta-bg .texto-descuento-container p {
    font-size: 1rem;
  }

  .cta-bg .texto-descuento-container a button {
    padding: 10px 20px;
  }

  .cta-bg .texto-descuento-container button a {
    font-size: 0.8rem;
  }

  .numero-grande {
    font-size: 3rem;
    left: 15px;
  }

  .porcentaje {
    font-size: 1.4rem;
    right: 0;
  }

  .numero-pequeño {
    right: 0;
    font-size: 1.3rem;
  }

  .container-garantia {
    width: 100%;
  }

  .orientado-container {
    margin-top: 25px;
    padding: 30px 15px;
  }

  h2.orientado-titulo {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .orientado-info p br {
    display: none;
  }

  .orientado-img {
    display: none;
  }

  .counters {
    height: auto;
    padding: 30px 0;
    margin-bottom: 40px;
  }

  .seccion-vacia {
    display: none;
  }

  .garantia-column-one,
  .garantia-column-two {
    width: 100%;
  }

  .seccion-informacion {
    flex-direction: column;
    width: 100%;
  }

  .garantia-column-one,
  .garantia-column-two {
    padding: 0;
  }

  .garantia .img-holder {
    display: none;
  }

  .garantia ul li {
    margin-top: 30px;
  }

  .imagen {
    display: none;
  }
}

@media only screen and (max-width: 465px) and (min-width: 425px) {
  .container-countdown {
    top: calc(764px - 100px);
  }

  .video-section {
    width: 100%;
  }

  .cta-home h2 {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 466px) and (min-width: 465px) {
  .container-countdown {
    top: calc(764px - 150px);
  }

  .video-section {
    margin-top: 240px;
  }

  .cta-home h2 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 500px) and (min-width: 466px) {
  .cta-home h2 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 600px) and (min-width: 466px) {
  .container-countdown {
    top: calc(764px - 150px);
  }

  .video-section {
    width: 100%;
  }
}

@media only screen and (max-width: 506px) and (min-width: 425px) {
  .cta-bg .texto-descuento-container {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 506px) and (max-width: 788px) {
  .cta-bg .texto-descuento-container {
    margin-top: 120px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .orientado-container {
    padding: 40px 0;
    margin-top: 40px;
  }

  h2.orientado-titulo {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .garantia h2 {
    display: inline-block;
  }

  .garantia {
    padding: 50px 0;
    height: max-content;
    justify-content: center;
  }

  .seccion-informacion {
    width: 100%;
  }

  .orientado-img,
  .orientado-img img,
  .imagen {
    display: none;
  }

  .titulo-garantia {
    width: 100%;
    display: flex;
    justify-content: center;
    position: static;
    margin-top: 25px;
  }

  .seccion-informacion p br {
    display: none;
  }

  .garantia .container-garantia {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    align-items: center;
  }

  .boton-inscribirse-orientado {
    width: max-content;
  }

  .boton-inscribirse-orientado {
    width: max-content;
  }

  .orientado-info-container {
    flex-direction: column;
    height: max-content;
  }

  .orientado-info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .orientado-info a {
    width: max-content;
    margin: 0 auto;
  }

  .orientado-info p {
    width: 100%;
    text-align: left;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 15px;
    width: 100%;
    font-size: 1rem;
    text-align: left;
  }

  section.first-section .content-home p {
    font-size: 1rem;
    color: black;
  }

  .titulo-quinta-seccion {
    text-align: center;
  }

  .tarjeta-home {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo,
  .tarjeta-home {
    flex-direction: column;
    align-items: center;
    height: max-content;
  }

  div.tarjeta-home-amarillo .content-home h3,
  div.tarjeta-home .content-home h3 {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }

  .content-home p {
    text-align: center;
    color: #fff;
  }

  .tarjeta-home-amarillo svg,
  .tarjeta-home svg {
    color: #fff;
    margin: 0 auto;
  }

  .content-home {
    text-align: center;
    height: 80%;
  }

  .tarjeta-home-amarillo:hover .content-home p {
    color: #fff;
  }

  .tarjeta-home-amarillo:hover {
    background-color: var(--color-secundario);
  }

  .tarjeta-home-amarillo:hover svg {
    color: #fff;
  }
}

@media only screen and (max-width: 600px) and (min-width: 425px) {
  .numero-grande {
    font-size: 4rem;
  }

  .counter-items h4 {
    font-size: 0.8rem;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 10px;
    width: 100%;
  }

  section.first-section .content-home p {
    font-size: 1rem;
  }

  .first-section .circle:before {
    display: block;
    width: 250px;
    height: 250px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 6%;
    top: 20px;
  }

  .cta-home .countdown span {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0;
  }

  .boton-inscribirse-2 {
    min-width: none;
    max-width: none;
    width: max-content;
  }

  .numero-pequeño {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container button a {
    font-size: 1rem;
  }

  .tarjeta-home .content p {
    font-size: 1rem;
  }

  .second-section p br,
  .content-home p br {
    display: none;
  }

  .container-items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
  }

  .counter-items {
    height: max-content;
  }

  section.counters svg:not(:root).svg-inline--fa {
    margin-top: 0;
    width: 10vw;
    height: max-content;
    margin-bottom: 5px;
    color: white;
  }

  .counter-items h3 {
    margin: 5px 0;
  }

  section.docentes-section {
    width: 100%;
    margin: 0;
  }

  .docente h3 {
    font-size: 6vw;
    margin: 10px 0;
  }

  div.docente p {
    font-size: 1.5rem;
  }

  .garantia h2 {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container h3 {
    font-size: 2rem;
  }

  .cta-bg .texto-descuento-container p {
    font-size: 1.4rem;
  }

  .docentes-list {
    display: block;
    padding: 0 20px;
  }

  .counter-items h3 {
    font-size: 1.5rem;
  }
  .counter-items h4 {
    font-size: 1.2rem;
  }

  .cta-bg .flex {
    display: block;
  }

  .first-section {
    display: block;
  }

  .first-section .circle:before {
    display: block;
    width: 300px;
    height: 300px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 20px;
  }

  .first-section .circle span {
    color: var(--color-principal);
  }

  .docente h3 {
    font-size: 3vw;
  }

  div.docente {
    margin-bottom: 40px;
  }

  section.garantia {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  ul.garantia-column-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-two {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-one,
  ul.garantia-column-two {
    margin-bottom: 0;
  }

  .garantia .img-holder {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .garantia .img-holder img {
    display: none;
  }

  footer .blog .entry .img {
    flex: none;
  }

  .garantia ul li {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .docentes-list img {
    width: 80%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 640px) {
  .cta-home h2 {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
  }

  .container-countdown {
    top: 480px;
  }

  .cta-home .countdown-holder a {
    padding: 15px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .cta-home h2 {
    font-size: 1.8rem;
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .linea {
    display: flex;
    width: 65%;
    background-color: var(--color-secundario);
    border-color: var(--color-secundario);
    margin: 30px 0;
  }

  .porcentaje,
  .numero-pequeño,
  .texto-amarillo {
    position: static;
  }

  .porcentaje {
    background-color: transparent;
  }
}

@media only screen and (min-width: 425px) and (max-width: 1024px) {
  .numero-grande {
    font-size: 9rem;
  }

  .porcentaje {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .porque-cdo .grid {
    grid-template-columns: 1fr;
  }

  section.cta-bg {
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 50px;
    margin-top: 0;
  }

  .second-section__title,
  .second-section__subtitle {
    text-align: center;
    width: 100%;
  }

  .second-section {
    height: auto;
    padding: 20px 40px;
    margin: 40px 0;
    background-position: right;
  }

  section.first-section .content-home h3 {
    font-size: 1.5rem;
  }

  .video-section {
    margin-bottom: 30px;
    width: 100%;
  }

  .first-section div.content-home {
    width: 100%;
    padding: 0 40px 0;
  }

  div.countdown div {
    margin: 0;
  }

  .cta-home .countdown h2 {
    padding: 0;
    color: white;
    font-size: 1.3rem;
  }

  .cta-home .countdown {
    width: 100%;
    column-gap: 20px;
  }

  .cta-home .countdown-holder a {
    margin: 0;
  }

  .cta-home .countdown-holder {
    padding: 0 20px;
    margin-top: 20px;
    width: 100%;
  }

  section.first-section {
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-player {
    width: 80%;
  }

  .first-section .content p {
    font-size: 1.3rem;
  }

  .first-section .content h3 {
    font-size: 1.6rem;
  }

  .first-section .circle {
    padding: 50px 0 0 25px;
    margin-bottom: 50px;
  }

  .container-countdown {
    width: 80%;
    max-width: none;
  }

  .cta-home {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0;
  }
}

/* @media only screen and (min-width: 600px) and (max-width: 640px) {
  .video-section {
    margin-top: 230px;
  }
} */

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .video-section {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .tarjeta-home,
  .tarjeta-home-amarillo {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .tarjeta-home,
  .tarjeta-home-amarillo {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 20%;
  }
}

@media only screen and (min-width: 821px) and (max-width: 900px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 25%;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .garantia .container-garantia {
    align-items: center;
    justify-content: center;
    padding: 0 30%;
  }
}

@media only screen and (min-width: 737px) and (max-width: 1024px) {
  .first-section div.content-home {
    padding: 0 120px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .video-section,
  .first-section div.content-home {
    width: 100%;
  }

  .content-home {
    width: 100%;
    padding: 0 40px 0;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    width: 100%;
  }

  .garantia h2 {
    width: max-content;
  }

  .imagen img,
  .imagen {
    display: none;
  }

  .seccion-informacion {
    width: 100%;
  }

  .second-section {
    padding: 30px 130px;
    margin-top: 50px;
    background-position-x: right;
  }

  section.first-section {
    flex-direction: column;
    margin-top: 330px;
  }

  .cta-home {
    flex-direction: column;
    row-gap: 20px;
  }

  .video-section {
    margin-bottom: 30px;
  }

  .second-section__title,
  .second-section__subtitle {
    text-align: center;
  }

  .video-player {
    width: 70%;
  }

  .cta-bg {
    padding: 0 5%;
    background-position: right;
  }

  .orientado-info p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .orientado-container {
    padding: 70px 30px;
  }

  .boton-inscribirse-orientado {
    width: max-content;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .cta-bg {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1140px) {
  .second-section {
    padding-left: 400px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1141px) and (max-width: 1200px) {
  .second-section {
    padding-left: 450px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .second-section {
    padding-left: 500px;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .second-section {
    padding-left: 600px;
    background-position-x: -180px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1220px) {
  .video-section,
  .content-home {
    width: 50%;
  }

  .first-section {
    padding: 0 30px;
    column-gap: 0;
  }

  .video-player {
    width: 100%;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    width: 100%;
  }

  section.first-section .content-home p br {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1169px) {
  .titulo-garantia {
    margin-bottom: 20px;
  }

  .garantia h2 {
    width: max-content;
  }

  .garantia h2 br {
    display: none;
  }

  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

@media only screen and (min-width: 1169px) and (max-width: 1360px) {
  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

@media only screen and (min-width: 1245px) and (max-width: 1440px) {
  .imagen img {
    top: 60px;
    right: 40px;
    width: 120%;
  }
}

/* Boton de Bitrix */
.b24-widget-button-position-bottom-right {
  right: 27px !important;
  bottom: 100px !important;
}

@media only screen and (max-width: 365px) {
  .container-countdown {
    top: 800px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 390px) {
  .container-countdown {
    top: 850px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 425px) {
  .container-countdown {
    top: 920px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 640px) {
  .container-countdown {
    top: 550px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .container-countdown {
    top: 590px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cta-home h2,
  .cta-home .countdown-holder,
  .cta-home .countdown-holder a {
    width: 100%;
  }

  .cta-home {
    padding: 30px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .container-countdown {
    top: 520px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .container-countdown {
    top: 520px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .container-countdown {
    top: 540px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .container-countdown {
    top: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1325px) {
  .container-countdown {
    top: 640px;
  }
}
