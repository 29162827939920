.post-content {
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  padding: 0 35px;
}

p.has-text-align-center {
  text-align: center !important;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.container div.post-content p:last-child {
  margin-bottom: 50px;
}

div.post-content p:not(.has-text-align-center) {
  margin: 15px 0;
}

div.post-content ul li {
  margin-left: 30px;
}
